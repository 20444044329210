@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.campaign-form {
  .campaign-form-container {
    @extend .form-content;

    .btn-submit-container {
      .btn-submit {
        background-color: transparent;
        border: 1px solid variable.$white;

        &:hover {
          border: 1px solid variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }

    .campaign-logo {
      &.logo-form {
        width: 100% !important;
        height: 126px !important;
        min-height: 126px !important;
        margin-left: 0 !important;

        .image-cert {
          width: 100%;
          height: 124px;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: 8px;
        }
      }

      .image-cert {
        max-height: 125px !important;
      }

      height: 100%;
      max-height: 130px;
    }

    .video-container {
      max-height: 560px;
    }

    .email_header_picture,
    .email_additional_picture {
      padding: 0 !important;
      width: 100%;
      height: 200px;

      .image-cert {
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
      }
    }
  }
}
