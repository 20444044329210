@use '../../../../utils/variable.scss' as variable;

.droppable-component {
  width: 99%;
  // min-height: 120px;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 0.2rem solid #13735f;
  border-radius: 10px;
  clear: both;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  box-shadow: 7px 7px 15px variable.$second-green-color;
  z-index: 100;
  // margin-bottom: 15px;
  position: absolute;
  bottom: 0;

  .pi {
    font-size: 4rem;
    opacity: 0.5;
    color: variable.$second-green-color;
  }
}
