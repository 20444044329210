@use '../../../../utils/variable.scss' as variable;

.p-tooltip {
  .p-tooltip-text {
    font-size: 16px;
  }
}

.content-builder-component-view {
  padding: 0;
  margin: 0;
  width: 100%;
  border: 0;
  border-radius: 0;

  .p-tree-container {
    padding: 0;
    margin: 0;

    > .p-treenode {
      border-bottom: 2px solid variable.$background-color-white-smoke;
    }

    > .p-treenode-droppoint {
      display: none;
    }

    .p-treenode-droppoint-active {
      height: 20px;
      background-color: variable.$pale-green;
    }

    .p-treenode {
      padding: 0;
      margin: 0;

      .p-treenode-content {
        height: 40px;
        box-shadow: none;
        outline: none;
        border-radius: 0;
        width: 100%;
        color: variable.$lavender-color;
        font-size: 13.5px;
        font-style: normal;
        font-weight: 700;
        border-left: 5px solid transparent;
        cursor: all-scroll;

        &:hover,
        &:focus {
          border: 0;
          box-shadow: none;
          outline: none;
          background-color: variable.$light-green-row;
          border-left: 5px solid transparent;
        }

        .p-treenode-label {
          width: calc(100% - 2rem);
        }

        .input-edit-label,
        .edit-inline-block {
          &:focus-visible {
            border: 1px solid variable.$black;
            outline: none;
          }
        }

        .p-link {
          border: 0;
          box-shadow: none;
          outline: none;

          &:hover,
          &:focus {
            border: 0;
            box-shadow: none;
            outline: none;
          }

          &:hover {
            background-color: variable.$light-green-row;
          }
        }

        &.p-highlight {
          background: variable.$light-green-row;
          color: variable.$second-green-color;
          border-left-color: variable.$pale-green;

          .p-tree-toggler {
            color: variable.$second-green-color;
          }
        }
      }

      .p-treenode-children {
        padding: 0;

        .p-treenode-content {
          width: 100%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          cursor: move;

          &.p-highlight {
            background: variable.$light-green-row;
            color: variable.$second-green-color;

            .p-treenode-icon {
              color: variable.$second-green-color;
            }
          }

          .p-link {
            display: none;
          }

          .p-treenode-icon {
            width: 20px;
            text-align: right;
          }

          .p-treenode-custom {
            padding: 0;
            // margin: 0 0 0 1rem;
            width: calc(100% - 30px);
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            span {
              width: calc(100% - 30px);
              font-size: 12px;
              cursor: move;
              text-align: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            i {
              width: 20px;
              text-align: left;

              &.fa-trash-can {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
