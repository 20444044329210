@use '../../../../utils/variable.scss' as variable;

.donation-edit-detail {
  &__header-fixed {
    height: 20px;
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0;
    z-index: 2;
  }

  &__header {
    position: sticky;
    top: 0px;
    left: 0;
    right: 0;
    background-color: variable.$background-color-white;
    z-index: 2;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      text-align: left;

      h3 {
        font-weight: 700;
      }

      .btn-action-container {
        display: flex;

        .donation-serial {
          font-weight: 700;
          padding-right: 10px;
        }

        .btn-action {
          cursor: pointer;
        }

        .p-button {
          background-color: transparent;
          border: none;
          color: unset;
          padding: 0;
          width: unset;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .row-amount {
      margin: 0;
      padding: 0;
    }

    .amount {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid variable.$label-color;
      padding: 0;

      .amount-container {
        display: flex;
        align-items: center;
        .value-amount,
        .currency-amount {
          font-size: 28px;
          padding-right: 5px;
        }

        .value-amount {
          font-weight: 700;
        }

        .currency-amount {
          color: variable.$value-color;
        }
      }

      .edit-action {
        display: flex;
        align-items: center;
        .p-button {
          padding: 0;
          width: 40px;
          height: 30px;

          .p-button-icon {
            font-size: 15px;
          }
        }

        .save-action {
          margin-right: 10px;
        }
      }
    }
  }

  &__container {
    .first-row {
      padding-bottom: 30px;
      padding-top: 30px;
    }
  }

  &__information {
    text-align: left;
  }

  &__information-title {
    border-bottom: 0.5px solid variable.$label-color;
    h5 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__information-content {
    padding-top: 15px;
    .content-row {
      margin-bottom: 10px;
      label:not(.wi-form-title) {
        color: variable.$label-color-donation;
        font-size: 14px;
        line-height: normal;
      }
      p:not(.wi-form-displayed-value) {
        margin-bottom: 10px;
        color: variable.$black;
        font-size: 14px;
        margin: 0;
      }
      a {
        color: variable.$primary-green-color;
        font-weight: 700;
        font-size: 14px;
      }

      .col-md-8,
      .col-md-7 {
        display: flex;
        align-items: flex-end;
      }

      .textDecorator {
        text-transform: capitalize;
      }

      .edit-donation-field {
        width: 100%;
        input {
          border-radius: 10px;
          border: 0.5px solid variable.$color-darkgray;
          padding: 3px 10px 3px 10px;
          font-size: 14px;
          width: 100%;

          &:focus-visible {
            outline: 0;
          }
        }

        .p-dropdown {
          width: 100%;
          height: 30px;
          border-radius: 10px;
          margin-bottom: 5px;

          &:focus-visible {
            box-shadow: none;
            outline: 0;
            border: 0.5px solid variable.$color-darkgray;
          }

          .p-dropdown-label {
            padding: 3px 0 3px 10px;
            font-size: 14px;
            color: variable.$black;
          }

          .p-dropdown-trigger-icon {
            font-size: 14px;
          }
        }

        .p-inputtextarea {
          border: 0.5px solid variable.$color-darkgray;
          font-size: 14px;
          width: 100%;
          color: variable.$black;
        }

        .p-calendar {
          width: 100%;
        }

        .p-inputtext:enabled:focus {
          box-shadow: none;
        }

        .p-checkbox {
          margin-bottom: 5px;
        }

        .has-error {
          border: 0.5px solid variable.$color-error;
        }

        .error {
          color: variable.$color-error;
          font-size: 12px;
          display: block;
        }
      }
    }
  }

  .p-timeline {
    .p-timeline-event {
      .p-timeline-event-separator {
        .custom-marker {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          justify-content: center;
          color: variable.$background-color-white;
          border-radius: 50%;
          z-index: 1;
          i {
            font-size: 10px;
          }
        }
      }

      .p-timeline-event-opposite {
        display: none;
      }
    }

    .p-timeline-event-content {
      .custome-content-timeline {
        font-size: 14px;
        .title-event {
          color: variable.$label-color-donation;
          font-weight: 700;
        }

        .message-event,
        .date-event {
          color: variable.$description-color;
          font-size: 12px;
        }

        .date-event {
          margin-bottom: 10px;
        }
      }
    }
  }
  .p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 1px !important;
  }
}

.p-menu {
  .p-menu-list {
    .p-menuitem.item-menu {
      font-size: 14px;
    }
  }
}

@media (max-width: 720px) {
  .donation-detail__header {
    left: 0 !important;
    h3 {
      margin: 0;
    }

    .header-container {
      padding-right: 0 !important;
    }
  }
}
