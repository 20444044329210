.wi-sidebar-v2 {
  .sidebar-loading-wrapper {
    position: absolute;
    width: calc(100%);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: variable.$background-color-white;
    opacity: 0.6;
    z-index: 9999;
  }

  &.sidebar-no-padding {
    .p-sidebar-content {
      padding: 0 !important;
    }
  }

  *:not(.pi):not(.fa-solid) {
    font-family: 'Poppins', sans-serif;
  }

  .filter-wrapper-v2 {
    .filter-form-container {
      .filter-elements {
        width: 100%;

        .p-button {
          width: 100%;
          height: 40px;
          padding: 5px 5px;
          color: variable.$black;
          background-color: variable.$white;
          border: 1px dashed variable.$primary-color;
          border-radius: 20px;
          outline: 0;
          box-shadow: none;
          cursor: pointer;

          .p-button-icon-left {
            margin-right: 5px;
            color: variable.$primary-color;
            background-color: rgba(variable.$primary-color, 0.1);
            border-radius: 20px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              font-size: 12px;
            }
          }

          .p-button-label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 280px;
            text-align: left;
            padding-left: 16px;
            font-weight: 500;
            font-size: variable.$text-default-size;
            font-family: 'Poppins', sans-serif;
          }
        }

        .filter-active {
          background-color: variable.$primary-color;
          color: variable.$white;

          .p-button-icon-left {
            color: variable.$primary-color;
            background-color: variable.$white;
          }
        }
      }
    }
  }
}

.p-sidebar.sidebar-right {
  .p-sidebar-header {
    display: none;
  }

  .p-sidebar-content {
    padding: 0 24px 0 24px;
    .headline {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .colse-sidebar {
        cursor: pointer;
        .pi-times {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: variable.$white;
          background-color: variable.$red-icon;
          border-radius: 20px;
          &::before {
            font-size: 8px;
          }
        }
      }
    }

    .p-button {
      .p-button-label {
        font-family: 'Poppins', sans-serif;
      }
    }
  }

  .sidebar-content {
    font-size: variable.$text-default-size;
    font-weight: 400;
    .headline h6 {
      font-weight: 700;
      font-size: variable.$text-icon-size;
      color: variable.$black;
      font-family: 'Poppins', sans-serif;
    }

    .row-content {
      @extend .row-common;
    }
  }
}
