@use '../../../../../../utils/variable.scss' as variable;

.search-auto {
  width: 100%;
  input {
    width: 100%;
  }

  .donor-selected {
    height: 48px;
    .donor-item-selected {
      display: flex;
      .name-item {
        font-size: 16px;
        width: 7rem;
        min-width: 7rem;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .icon {
          color: variable.$primary-green-color;
          padding-right: 5px;
        }

        .name {
          font-weight: 700;
          font-family: 'Poppins', sans-serif;
        }
      }

      .email-item {
        font-size: 16px;
        color: variable.$status-cancelled;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 10px;
        font-family: 'Poppins', sans-serif;
      }
    }
  }
}

.p-overlaypanel.overlaypanel-search-donor {
  width: calc(35em - 4 * 1.5 * 0.5rem) !important;
  .p-overlaypanel-content {
    padding: 0 !important;
  }

  &::before,
  &::after {
    display: none;
  }

  .donors-panel {
    .add-new-donor {
      display: flex;
      color: variable.$primary-green-color;
      font-size: 13px;
      font-weight: 700;
      padding: 10px;
      padding-bottom: 0;
      padding-left: 1rem;
      cursor: pointer;
      .title {
        font-family: 'Poppins', sans-serif;
      }
      i {
        padding-right: 1rem;
      }
    }

    .custom-dropdown-panel {
      padding-top: 10px;
      height: 15rem;
      min-height: 15rem;
      overflow: auto;

      .custom-dropdown-item {
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 5px;
        padding-left: 1rem;
        display: flex;
        cursor: pointer;

        &:hover {
          background-color: variable.$second-yellow-color;
          color: variable.$primary-green-color;

          .email-donor {
            color: variable.$white;
          }
        }

        .name-donor {
          padding-right: 1rem;
          min-width: 7rem;
          width: 7rem;
          font-size: 14px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: 'Poppins', sans-serif;
        }

        .email-donor {
          color: variable.$status-cancelled;
          font-size: 14px;
          font-family: 'Poppins', sans-serif;
        }
      }

      .p-highlight-item {
        background-color: #a9a9a924;
        color: variable.$second-green-color;
      }
    }
  }
}
