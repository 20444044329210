@use '../../../../utils/variable.scss' as variable;

.p-inputgroup-container {
  .p-inputgroup-addon {
    max-height: 48px;
  }

  input {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: variable.$background-color-white-gray;
  color: variable.$primary-green-color !important;
}

.goal-name {
  p {
    margin-bottom: 0;
  }
}

.goal-title {
  display: flex;
  justify-content: space-between;
  width: 500px;

  div {
    font-weight: 700;
    color: variable.$primary-green-color;
  }
}
