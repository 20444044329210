.draggable-component {
  padding: 0px 0px;
  border: 5px solid white;
  box-shadow: 0px 0px 1px grey;
  border-radius: 10px;
  cursor: all-scroll;
  flex: 1 1 20%;
  max-width: 150px;
  min-width: 150px;
  height: 140px;
  overflow: hidden;
  margin: 5px;
  display: flex;
  flex-direction: column-reverse;

  &:hover {
    .cta-thumbnail {
      // transition: width 2s easy-in-out;
      animation: showout1 1.5s ease-in infinite;
    }
  }

  &.empty-draggable {
    box-shadow: none;
    border: none;
    padding: 0 10px;
    margin: 1px 10px;
  }

  .title-draggable-component {
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    color: #777777;
    text-align: center;
    margin: 0px;
    margin-top: 10px;
  }

  .cta-thumbnail {
    // width: 120%;
    // margin-left: -10%;
    // transition: width 2s easy-in-out;
  }

  @keyframes showout1 {
    0% {
      scale: 1;
    }
    80% {
      scale: 1.2;
    }
    100% {
      scale: 1;
    }
  }
  @keyframes zoom-in-img {
    0% {
      scale: 1.2;
    }
    100% {
      scale: 1;
    }
  }
}

// <span title="Save Page" class="mode-item" style="
//     font-size: 14px;
//     font-weight: 700;
//     padding: 1;
//     margin: auto;
//     border: 1px solid #13735f;
//     border-radius: 20px;
//     background: white;
//     color: #13735f;
//     padding: 5px 15px;
//     width: 120px;
//     background: #e6ce00;
//     / color: white; /
// ">Publish &gt;
// </span>
