@use '../../../utils/variable.scss' as variable;

.p-paginator.wi-paginator-container-v2 {
  > .p-button {
    padding: 0 !important;
    width: variable.$primary-element-height !important;
    min-width: variable.$primary-element-height !important;
    height: variable.$primary-element-height !important;
    font-weight: 500;
  }

  .p-paginator-pages button {
    border: 0;
    box-shadow: none;
    outline: 0;

    &.p-highlight {
      background-color: variable.$primary-color !important;
      color: variable.$white !important;
    }
  }
  .p-paginator-next:focus,
  .p-paginator-prev:focus,
  .p-inputwrapper-focus:focus {
    box-shadow: none;
  }

  .p-paginator-pages {
    margin: 0.143rem;
    height: variable.$primary-element-height !important;
    padding: 0;

    .p-paginator-page {
      height: variable.$primary-element-height !important;
      min-width: variable.$primary-element-height !important;
      width: variable.$primary-element-height !important;
      font-size: 14px;
      font-weight: 500;
      margin: 0 4px;
    }
  }

  .p-dropdown {
    border-radius: 20px;
    height: variable.$primary-element-height !important;
    box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
    border: none !important;
    outline: none !important;

    &:not(.p-disabled):hover,
    &:not(.p-disabled):focus,
    &:not(.p-disabled).p-focus {
      box-shadow: 0px 0px 2px 0px variable.$box-shadow-hover-color !important;
    }

    .p-dropdown-label {
      padding: 8px 24px;
    }

    .p-dropdown-panel {
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
      margin-top: -8px;

      .p-dropdown-items {
        padding: 0;

        .p-dropdown-item {
          padding: 12px 16px;
          border-bottom: 1px solid variable.$background-header;
          display: flex;
          justify-content: space-evenly;

          &:last-child {
            border-bottom: none;
          }

          &.p-highlight {
            color: variable.$primary-color !important;
            background: variable.$background-header;
          }
        }
      }
    }
  }
}
