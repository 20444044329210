.wi-form-selectColor-v2 {
  width: 200px;
  box-shadow: none;
  outline: none;

  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
  }

  &.p-dropdown:not(.p-disabled) {
    &.p-focus {
      box-shadow: none;
      outline: none;
      border-color: #13735f;
    }

    &:hover {
      border-color: #13735f;
    }
  }

  .border-content-item {
    display: flex;

    .content-item-color {
      height: 25px;
      width: 30px;
      margin-right: 10px;
      border: 1px solid #c6c6c6;
    }
  }

  .p-highlight {
    .border-content-item {
      .content-text-color {
        color: #0a708f;
        font-weight: bold;
      }
    }
    box-shadow: none !important;
  }
}
