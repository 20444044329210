@use '../../../../utils/variable.scss' as variable;

.wi-form-select-button {
  &.p-dropdown {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 40px;
    border-radius: 16px;

    .p-dropdown-label {
      display: flex;
      align-items: center;
      color: variable.$second-green-color;
      font-size: 13px;
    }

    &:focus-visible {
      box-shadow: none;
      outline: none;
      border: 1px solid variable.$second-green-color !important;
    }

    .p-dropdown-trigger {
      transition: 0.16s ease-in-out all;

      .p-dropdown-trigger-icon {
        transform: rotate(0deg);
        transition: 0.16s ease-in-out all;
        color: variable.$second-green-color;
      }
    }

    .p-dropdown-panel {
      border-radius: 0 0 16px 16px;
      box-shadow: none !important;
      transform-origin: center top !important;
      top: 27px !important;

      .p-dropdown-items-wrapper {
        border-left: 1px solid variable.$second-green-color;
        border-bottom: 1px solid variable.$second-green-color;
        border-right: 1px solid variable.$second-green-color;
        border-radius: 0 0 16px 16px;
        overflow-y: auto;
        max-height: 210px;

        .p-dropdown-item {
          font-weight: 600;
          padding: 5px 16px;
          font-size: 13px;
          color: variable.$second-green-color;
          text-transform: none;
          cursor: pointer;
        }

        .p-dropdown-item.p-highlight {
          background: variable.$background-color-white-gray;
          color: variable.$primary-green-color !important;
        }
      }
    }
  }

  &.p-dropdown:not(.p-disabled):hover,
  &.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    outline: none;
    border: 1px solid variable.$second-green-color !important;
  }

  &.p-dropdown:not(.p-disabled).p-focus {
    .p-dropdown-trigger {
      transition: 0.16s ease-in-out all;

      .p-dropdown-trigger-icon {
        transform: rotate(180deg);
        transition: 0.16s ease-in-out all;
      }
    }
  }

  &.p-dropdown-panel {
    left: -1px !important;
    width: calc(100% + 2px) !important;

    .p-dropdown-header {
      padding: 0.75rem 0.5rem !important;

      .p-dropdown-filter-container {
        height: 25px !important;

        .p-inputtext {
          height: 100% !important;
          font-size: 13pt;
        }
      }
    }
  }
}
