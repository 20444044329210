@use '../../../utils/variable.scss' as variable;

.input-upload {
  display: none;
}

.label-upload-field {
  padding: 55px 0;
  margin: 1rem 0rem;
  width: 125px;
  height: 165px;
  display: flex !important;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 2px dashed variable.$second-green-color;
  background-color: variable.$dark-green;
  cursor: pointer;
  // overflow: hidden;
  position: relative;

  &.img-frame-1-1 {
    width: 300px;
    height: 300px;
    padding: 0;
  }

  &.img-frame-16-9 {
    min-width: 300px;
    width: 100%;
    height: calc(480px * 9 / 16);
    padding: 0;
  }

  &.img-frame-9-16 {
    width: 300px;
    height: calc(300px * 16 / 9);
    padding: 0;
  }

  &.img-frame-3-4 {
    width: 300px;
    height: 400px;
    padding: 0;
  }

  &.img-frame-1125-1000 {
    width: 300px;
    height: calc(300px / 1.125);
    padding: 0;
  }

  &.upload-error {
    border: 2px dashed variable.$red-color;
    background-color: variable.$dark-red;
  }

  p {
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .image {
    width: 100%;
    height: 100%;
    max-height: none !important;
  }

  .drone-cert {
    height: 100%;
    width: 100%;
    max-height: none;
  }

  .banner-cert {
    height: 100%;
    width: 100%;
    max-height: none;
    object-fit: cover !important;
  }

  .image-cert {
    width: 100%;
    height: 100%;
    max-height: none;

    &.media-preview {
      object-fit: contain;
    }
  }

  img {
    cursor: pointer;
    max-height: 80px;
  }

  .orientation-image-container {
    display: inline-block;
  }

  .remove-icon {
    position: absolute;
    top: -8px;
    width: 18px;
    height: 18px;
    left: calc(100% - 8px) !important;
    background: variable.$red-error;
    color: variable.$text-color-white;
    border-radius: 50%;
    padding: 1px;

    i {
      font-size: 12px;
      top: 9px;
      position: absolute;
      left: 5px;
    }
  }
}
