@use '../../../../../utils/variable.scss' as variable;

.dialog-text-html {
  position: relative;

  .p-dialog-footer {
    .p-dialog .p-dialog-footer button {
      margin: 0;
    }

    .button-confirm {
      background: variable.$second-green-color;
      color: variable.$white;

      &:hover {
        background-color: variable.$second-yellow-color;
        border-color: variable.$second-yellow-color;
        color: variable.$second-green-color;
      }
    }
  }

  .p-dialog-header {
    background-color: variable.$second-green-color;
    color: variable.$white;
    padding: 0;
  }

  .content-text {
    display: flex;
    justify-content: space-evenly;
  }

  .button-hide {
    color: variable.$red-color;
  }

  .p-button.p-button-text:enabled:hover {
    color: variable.$second-green-color;
  }

  .dialog-html {
    margin-top: 10px;
    width: 50%;
    display: flex;
    flex-flow: column nowrap;

    &:nth-child(1) {
      margin-right: 10px;
    }

    label {
      margin-bottom: 10px;
    }
  }

  .dialog-review {
    width: 100%;
  }

  .preview-html {
    border: 1px dashed variable.$second-green-color;
    height: 410px;
    overflow-y: scroll;
  }

  .p-dialog-header-icons .p-dialog-header-icon {
    color: variable.$white;

    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0 transparent;
    }

    &:hover {
      background-color: transparent !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .dialog-text-html {
    width: 90vw;
  }
}
