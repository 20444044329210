@use '../../../../utils/variable.scss' as variable;
@import '../../../../styles/style-common-v2.scss';

.user-form {
  width: 100%;
  height: calc(100vh - 84px - 73px);
  overflow-y: auto;

  .user-form-container {
    width: 552px;
    margin: auto;
    @extend .row-common;

    .user-column {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .background-edit-user {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        background-color: variable.$background-edit-avatar;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;

        svg {
          width: 24px;
          height: 24px;
          path {
            stroke: variable.$white;
          }
        }
      }
    }
  }
}
