

.p-selectbutton.wi-selectbutton-three {
    width: 100%;
    .p-button {
      width: calc(100% / 3);
    }
}

.p-selectbutton.wi-selectbutton-two {
  width: 100%;
  .p-button {
    width: 50%;
  }
}