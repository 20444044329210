@use '../../../../utils/variable.scss' as variable;

.withdraw-consent-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background-color: variable.$background-backdrop;
  .withdraw-consent-backdrop-container {
    width: 100%;
    height: 100%;
    position: relative;

    .withdraw-consent-content {
      width: 412px;
      background-color: variable.$white;
      position: absolute;
      left: 35%;
      bottom: 35%;
      border-radius: 44px;

      &.withdraw-consent-show {
        animation: scale-show 0.3s;
        animation-fill-mode: forwards;
        transform-origin: center;
      }

      .withdraw-consent-container {
        text-align: center;
        .withdraw-consent-icon {
          display: flex;
          justify-content: center;
        }
        .withdraw-consent-text {
          font-size: variable.$text-default-size;
          font-family: 'Poppins', sans-serif;
          color: variable.$black;

          .title {
            font-weight: 700;
          }
        }
        .withdraw-consent-buttons {
          display: flex;
          justify-content: space-between;
          gap: 24px;
        }
      }
    }
  }
}

.withdraw-consent-none {
  animation: scale-hide 0.3s;
  animation-fill-mode: forwards;
  transform-origin: center center;
  display: inline-block;
}

.withdraw-consent-backdrop-none {
  animation: scale-backdrop 0.3s;
  animation-fill-mode: forwards;
  transform-origin: center center;
  display: inline-block;
}

@keyframes scale-backdrop {
  0% {
    opacity: 1;
    display: inline-block;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes scale-show {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes scale-hide {
  0% {
    display: inline-block;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: center center;
  }
  50% {
    display: inline-block;
    opacity: 0.5;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform-origin: center center;
  }
  100% {
    display: none !important;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    transform-origin: center center;
  }
}
