@use '../../utils/variable.scss' as variable;

.vertical.wi-menu.p-menu {
  .menu-items {
    min-width: 210px;
    max-width: 210px;
    transition: all 0.3s;
  }

  .dropdown-menuitem {
    display: none;
    position: absolute;
    bottom: 0%;
    left: calc(100% - -1px);
    z-index: 2206;
    border: none;
    background-color: variable.$second-green-color;
    // border-radius: 5px;

    &.show {
      display: flex !important;
      flex-direction: column;
    }

    &.hidden {
      display: none !important;
    }

    .items-dropdown {
      a,
      span {
        color: variable.$text-color-white !important;
        background-color: variable.$second-green-color;
      }

      &:first-child {
        background-color: variable.$light-green-row;
      }

      .p-menuitem-text {
        display: block !important;
      }

      &:hover {
        a,
        span {
          color: variable.$second-green-color !important;
          background-color: variable.$bg-hover-menuitem !important;
        }
      }
    }
  }
}

.horizontal.wi-menu.p-menu {
  .menu-items {
    width: 210px;
    min-width: 210px;
    transition: all 0.3s;
  }

  .dropdown-menuitem {
    padding: 5px 0;
    display: none;
    position: absolute;
    left: 0;
    z-index: 2206;
    border: none;
    background: variable.$second-green-color;
    box-shadow: variable.$second-green-color 0px 3px 8px;

    &.show {
      display: flex !important;
      flex-direction: column;
    }

    &.hidden {
      display: none !important;
    }

    .items-dropdown {
      > .p-menuitem-link {
        padding: 10px 20px;

        > .p-menuitem-icon,
        > .p-menuitem-text {
          color: variable.$text-color-white;
          background-color: variable.$transparent;
          font-size: 13px;
          font-weight: 700;
        }

        > .p-menuitem-icon {
          margin-right: 0.5rem;
          width: 20px;
        }

        &:focus {
          box-shadow: none;
        }
      }

      > .p-menuitem-link:hover {
        border-radius: 20px;
        box-shadow: none;

        > .p-menuitem-icon,
        > .p-menuitem-text {
          color: variable.$second-green-color;
          background: variable.$light-green-row;
        }
      }
    }
  }
}
