.logo-circle {
  border-radius: 50% !important;
  object-fit: cover;
  object-position: center;

  height: 72px;
  width: 72px;
  min-width: 72px;
  box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
}

.logo-strict {
  overflow: hidden;
  height: 72px;
  width: 140px;

  img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}

.user-avatar-small {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;

  .user-avatar-default {
    height: 100%;
    background-color: variable.$background-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variable.$text-color-white;
    font-weight: 400;
    font-size: 10px !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.header-background-image {
  width: 100%;
  height: 480px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 24px;
  }

  &.background-image-default {
    background-color: variable.$color-darkgray;
    border-radius: 24px;
  }
}

.logo-partner-strict {
  height: 72px;
  background-color: variable.$white;
  overflow: hidden;

  img {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.user-avatar-with-role {
  width: 72px;
  height: 72px;
  position: relative;
  border-radius: 50%;
  box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;

  .avatar {
    border-radius: 50%;
  }

  .user-avatar-default {
    height: 100%;
    border-radius: 50%;
    background-color: variable.$background-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    color: variable.$text-color-white;
    font-weight: 500;
    font-size: variable.$text-h2-size !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .user-role {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.big {
    width: 104px;
    height: 104px;

    .user-avatar-default {
      font-size: 32px !important;
    }

    .user-role {
      right: 10px;
      width: 24px;
      height: 24px;
      z-index: 3;
    }
  }
}
