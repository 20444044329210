@use '../../../../utils/variable.scss' as variable;

.snack-bar-component {
  position: absolute;
  left: calc(50% - 345px);
  z-index: 2;
  display: block;
  bottom: 40px;
  animation: runDisplay 0.3s;
  animation-fill-mode: forwards;

  .snack-bar-container {
    display: flex;
    justify-content: space-between;
    background-color: variable.$black;
    border-radius: 30px;
    align-items: center;

    .items-selected {
      display: flex;
      justify-content: space-between;
      color: variable.$white;
      align-items: center;
      font-size: 15px;

      .number-selected {
        border: 0.5px;
        border-radius: 20px;
        background-color: variable.$blur-color;
        padding: 0px 10px;
        margin-right: 15px;
        font-weight: 700;
      }

      button {
        background-color: transparent;
        border: transparent;
        transform: rotate(45deg);
        &:hover {
          background-color: transparent;
          border: transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .btns-container {
      display: flex;
      align-items: center;
      margin-left: 3rem;

      button {
        background-color: variable.$blur-color;
        border: transparent;
        border-radius: 30px;
        margin: 5px 10px;
        font-size: 14px;
        height: 35px;

        &:hover {
          background-color: variable.$blur-color;
          border: transparent;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.snack-bar-none {
  display: none;
  animation: scale-display--reversed 0.3s;
  animation-fill-mode: forwards;
  display: inline-flex;
}

@keyframes runDisplay {
  0% {
    transform: translateY(70%) scale(0.2);
  }

  100% {
    transform: translateY(0) scale(1);
  }
}

@keyframes runNone {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(200%);
  }
}

@keyframes scale-display--reversed {
  0% {
    display: inline-flex;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  99% {
    display: inline-flex;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    display: none !important;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
