@use '../../utils/variable.scss' as variable;
@import '~maplibre-gl/dist/maplibre-gl.css';

@mixin uploadCert($width, $height, $position, $top, $left, $bottom, $right, $object-fit) {
  width: $width;
  height: $height;
  object-fit: $object-fit;
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
  margin: 0;
}

.wi-tag-custome {
  padding: 2px 5px;
  min-width: 80px;
  font-size: 12px;
  border-radius: 20px;

  .p-tag-value {
    text-transform: capitalize;
  }

  .p-tag-icon {
    display: none;
  }

  &.tag-status-archived {
    background: variable.$light-grey-color;
  }
}

.tag-status-closed-custome {
  background: variable.$light-grey-color;
}

.headline-common {
  display: flex;
  justify-content: flex-start;
  h3 {
    font-family: variable.$font-name-Sora;
  }
}

.table-common {
  margin-top: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;

  .p-datatable {
    // flex: 1;

    .p-datatable-wrapper {
      .p-datatable-table {
        font-size: 12px;

        thead {
          tr {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            cursor: pointer;
            margin: 0;
            text-transform: initial !important;

            th {
              background-color: variable.$second-green-color;
              color: variable.$text-color-white;
              display: flex;
              justify-content: center;
              padding: 10px 15px;
              font-weight: 700;
            }
          }
        }

        .row-header {
          &:hover {
            background-color: variable.$background-color-white !important;
          }
        }

        th.upper-case {
          text-transform: initial !important;
        }

        tr {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          cursor: pointer;
          border-radius: 8px;
          margin-top: 10px;

          td {
            display: flex;
            white-space: wrap;
            overflow: hidden;
            align-items: center;
            border: none;
            padding: 7px 15px;
          }

          .upper-case {
            text-transform: uppercase;
          }

          .invalid-land {
            background: variable.$red-icon;
            padding: 5px;
            border-radius: 5px;
            color: variable.$text-color-white;
          }

          .logo-image {
            width: 100%;
            height: 100px;
            border-radius: 8px;
            object-fit: cover;

            &.not-found {
              padding: 1.2rem;
            }
          }

          .p-tag-error {
            background-color: variable.$red-icon !important;
          }

          .btn-action {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            i {
              padding: 10px;
              border-radius: 50%;
              background: variable.$second-green-color;
              color: variable.$text-color-white;

              &.delete-icon {
                background: variable.$red-icon;
              }

              &.disabled {
                opacity: 0.4;
                cursor: default !important;
                pointer-events: none;

                &:hover {
                  color: variable.$text-color-white;
                }
              }

              &:nth-child(even) {
                margin-left: 10px;
              }
              &:hover {
                color: variable.$coupon-new;
              }
            }

            button {
              font-size: 1.2rem;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              background: variable.$second-green-color !important;
              outline: none;
              box-shadow: none;
              border: none;
              color: variable.$text-color-white !important;
              &:hover {
                i {
                  color: variable.$coupon-new !important;
                }
                color: variable.$coupon-new !important;
              }
              i {
                background: none;
                color: variable.$second-green-color;
                color: variable.$text-color-white !important;
              }
            }
          }
        }

        tr:nth-child(even) {
          background-color: variable.$light-green-row;
        }

        tr:nth-child(odd) {
          background-color: variable.$snow;
        }
      }
    }
  }
}

.table-common-extra {
  .table-ref-link-cell {
    text-decoration: none;
    color: variable.$second-green-color;
    font-weight: 700;

    &:hover {
      color: variable.$primary-green-color;
    }
  }

  .p-sortable-column {
    &.p-highlight {
      .p-sortable-column-icon {
        font-size: 12px;
        color: variable.$primary-green-color !important;
      }
    }
    .p-sortable-column-icon {
      font-size: 12px;
    }
  }

  .p-datatable-table thead tr th {
    justify-content: left !important;
    background: variable.$background-color-white !important;
    color: variable.$black !important;
    border-top: variable.$pattent-blue 1px solid;
  }

  tr {
    background: variable.$background-color-white !important;
    border-bottom: variable.$primary-gray 1px solid;
    margin: 0px !important;
    border-radius: 0px !important;

    &.p-highlight {
      color: variable.$slate-gray !important;
    }
  }

  .p-selection-column {
    .p-checkbox .p-checkbox-box:not(.p-highlight) {
      border-color: variable.$primary-gray;
    }
  }

  .p-datatable-thead,
  .selectable-donation-row {
    .p-selection-column {
      .p-checkbox .p-checkbox-box:not(.p-highlight) {
        border-color: variable.$color-darkgray;
      }
    }

    &.p-highlight {
      td {
        background: variable.$background-color-white-smoke;
      }
    }
  }

  .p-datatable .p-datatable-wrapper {
    height: calc(100vh - 210px);

    .disable-icon {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.table-global-content {
  margin-top: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);

  .image-text {
    text-transform: uppercase;
  }
}

.search-box-common {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  input {
    width: 100%;
    height: 45px;
    color: variable.$default-color-text;
    border: 2px solid variable.$second-green-color;
    border-radius: 10px;

    &:focus {
      box-shadow: none;
    }
  }
}

.button-common {
  button {
    color: variable.$second-green-color !important;
    background-color: variable.$second-yellow-color !important;
    border-color: variable.$second-yellow-color !important;
    text-align: center;
    font-weight: 700;
    border-radius: 40px !important;
    text-decoration: none;
    transition: 0.2s ease-in 0s;
    line-height: 22px;
    height: max-content;
    padding: 10px 30px;
    margin: 0;
    &:hover {
      color: variable.$text-color-white !important;
      background-color: variable.$second-green-color !important;
      border-color: variable.$second-green-color !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: variable.$second-green-color;
  background: variable.$second-green-color;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
  &:hover {
    border-color: variable.$second-green-color;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  &:hover {
    border-color: variable.$second-green-color;
    background: variable.$second-green-color;
  }
}

.p-dialog-mask.p-component-overlay:not(.top-mask-dialog) {
  z-index: 2700 !important;
}

.top-mask-dialog {
  z-index: 5000 !important;
}

.p-sidebar-mask.p-component-overlay {
  z-index: 2250 !important;
}

.tox-tinymce-aux {
  z-index: 3500 !important;
}

.sticky-header-form {
  height: 50px;
  background: variable.$white;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2205;
}

.p-toast {
  z-index: 999999999 !important;
}

.form-content {
  .row-title {
    h3 {
      margin: 0;
      color: variable.$coupon-new;
      font-size: 25px;
    }
  }

  .btn-submit-container {
    margin-left: 10pt;
    margin-right: 10pt;
    background-color: variable.$second-green-color;
    border-radius: 16px;

    .title-content {
      margin-left: 10px;
      color: variable.$white;

      .title-header {
        margin-right: 5px;
        font-weight: 600;
      }

      i {
        cursor: pointer;
      }
    }

    button {
      height: 30px;
      font-size: 13px;
      box-shadow: none;
      background-color: variable.$primary-green-color;

      .p-button-icon {
        font-size: 15px;
        margin-left: 7px;
      }

      &:hover {
        box-shadow: none;
        background-color: variable.$second-yellow-color;
      }
    }
  }

  .AddDonationButton {
    display: flex;
    justify-content: flex-end;
    margin-top: -20px;
    button {
      background-color: variable.$primary-green-color;
      height: 36px;
      width: 36px;
      border: none;
    }
  }

  .row-content {
    .element-form {
      padding: 10px 0px;

      .a4 {
        zoom: 0.7;
      }

      .input-upload {
        display: none;
      }

      .label-upload-field {
        padding: 10% 0;
      }

      label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: variable.$light-slate-gray;
        padding-bottom: 4px;
        position: relative;
        padding-right: 8px;
        font-style: italic;

        .required-label {
          color: variable.$red-error;
        }
      }

      .p-dropdown {
        width: 100%;
        height: 42px;
        border-radius: 16px;
        position: relative;

        .p-dropdown-label {
          display: flex;
          align-items: center;
          color: variable.$second-green-color;
          font-size: 14px;
        }

        &:focus-visible {
          box-shadow: none;
          outline: none;
          border: 1px solid variable.$second-green-color !important;
        }

        .p-dropdown-trigger {
          transition: 0.16s ease-in-out all;
          .p-dropdown-trigger-icon {
            transform: rotate(0deg);
            transition: 0.16s ease-in-out all;
            color: variable.$second-green-color;
          }
        }

        .p-dropdown-panel {
          border-radius: 16px;
          box-shadow: none !important;

          .p-dropdown-header {
            padding: 0.2rem 0.75rem;
            margin: 0;
            background-color: transparent;
            border: 1px solid variable.$second-green-color;
            border-radius: 0;

            .p-dropdown-filter-container input {
              height: 32px;
              font-size: 16px;
              box-shadow: none;
              border: 1px solid variable.$second-green-color !important;
            }
          }

          .p-dropdown-items-wrapper {
            border: 1px solid variable.$second-green-color;
            border-radius: 6px;
            overflow-y: auto;
            max-height: 210px;

            .p-dropdown-item {
              font-weight: 600;
              padding: 5px 16px;
              font-size: 12px;
              color: variable.$second-green-color;
              text-transform: none;
              cursor: pointer;
            }

            .p-dropdown-item.p-highlight {
              background: variable.$background-color-white-gray;
              color: variable.$primary-green-color !important;
            }
          }
        }
      }

      .p-dropdown:not(.p-disabled):hover,
      .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none;
        outline: none;
        border: 1px solid variable.$second-green-color !important;
      }

      .p-dropdown:not(.p-disabled).p-focus {
        .p-dropdown-trigger {
          transition: 0.16s ease-in-out all;
          .p-dropdown-trigger-icon {
            transform: rotate(180deg);
            transition: 0.16s ease-in-out all;
          }
        }
      }

      button.p-button {
        display: flex;
        justify-content: space-between;
        border: 1px solid variable.$background-color-white-gray;
        color: variable.$black-gray-color;
        border-radius: 16px;
        height: 48px;
        padding: 8px 16px;
        width: 100%;
        background: variable.$background-color-white;
        text-align: left;
        position: relative;
        cursor: pointer;

        &:focus {
          box-shadow: none;
          border: 1px solid variable.$second-green-color;
        }

        .chevron {
          font-weight: bolder;
          font-size: 15px;
          transition: transform 0.3s ease-in-out;
          .chevron-left {
            position: absolute;
            right: 23px;
            top: 8px;
            transform: rotate(45deg);
          }

          .chevron-right {
            position: absolute;
            right: 16px;
            transform: rotate(-45deg);
          }

          .line-left {
            position: absolute;
            right: 27px;
            transform: rotate(-45deg);
          }

          .line-right {
            transform: rotate(45deg);
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      .p-inputnumber,
      .p-calendar {
        width: 100%;
      }

      input {
        border: 1px solid variable.$background-color-white-gray;
        color: variable.$black-gray-color;
        border-radius: 16px;
        padding: 8px 16px;
        height: 42px;
        font-size: 14px;
        width: 100%;
        background: variable.$background-color-white;
        text-align: left;
        position: relative;
        box-shadow: none;
        &:focus,
        &:focus-visible {
          outline: none;
          border: 1px solid variable.$second-green-color !important;
          box-shadow: none;
        }
        &:disabled {
          cursor: not-allowed;
          pointer-events: none;
          background: variable.$background-color-white-gray;
          opacity: 0.4;
        }
      }

      textarea {
        border: 1px solid variable.$background-color-white-gray;
        color: variable.$black-gray-color;
        border-radius: 16px;
        padding: 8px 16px;
        width: 100%;
        background: variable.$background-color-white;
        text-align: left;
        position: relative;
        min-height: 144px;
        font-size: 14px;

        &:focus-visible {
          outline: none;
          border: 1px solid variable.$second-green-color !important;
        }
      }

      .has-error {
        border: 1px solid variable.$red-error;
        border-radius: 16px;
      }

      .dropdown-menu {
        border: 1px solid variable.$second-green-color;
        width: 100%;
        border-radius: 16px;
        margin: 10px 0px;
      }

      .error {
        position: relative;
        color: variable.$red-error;
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1.15;
        font-size: 12px;
        font-weight: 400;
        padding: 10px 0px 0px 24px;

        &::before {
          display: block;
          content: url(../../assets/images/errorIcon.svg);
          position: absolute;
          left: 0px;
          top: 8px;
          width: 18px;
          height: 18px;
        }
      }

      .description {
        color: variable.$light-slate-gray;
        padding: 5px 0px 0px;
        font-size: 14px;

        a {
          color: variable.$second-green-color;
          font-weight: 700;
          text-decoration: underline;
          transition: color 0.3s ease;
        }
      }

      .animal-image {
        @include uploadCert(168px, 168px, absolute, 54.5px, -83px, 0, 0, 0);
        padding: 0;
        border-width: 2px;
      }

      .tree-image {
        @include uploadCert(168px, 168px, absolute, '', '', -64px, 53px, 0);
        padding: 0;
        border-width: 2px;
      }

      .drone-image {
        @include uploadCert(100%, 100%, absolute, 0, 0, 0, 0, cover);
        padding: 0;
        border-width: 2px;
      }

      .campaign-logo {
        @include uploadCert(80%, 100%, relative, 0, 0, 0, 0, cover);
        margin-left: 10pt;
        min-height: 84px;
      }

      .land-logo {
        @include uploadCert(35%, 172px, relative, 0, auto, 0, auto, cover);
        padding: 0;
        border-width: 2px;
        margin-top: 40px;
      }

      .qr-code {
        @include uploadCert(50pt, 50pt, relative, 0, 0, 0, 0, cover);
        font-size: 8px;
        padding: 0;
        border-width: 2px;
      }
    }

    .title {
      font-size: 14px;
      font-weight: 600;
    }

    .card {
      margin: 0;
      padding: 0;

      .p-datatable .p-datatable-table .p-datatable-thead tr th,
      .p-datatable .p-datatable-table .p-datatable-tbody tr td {
        padding: 0.75rem;
        font-size: 14px;
      }
    }
  }

  .p-accordion.p-component .p-accordion-header .p-accordion-header-link {
    padding: 10px 15px;
    font-size: 14px;
    &:focus {
      box-shadow: none;
    }
  }
}

.hidden-element {
  display: none;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: variable.$second-green-color;
}

.footer-global-common {
  height: calc(100vh - 200px);

  .p-datatable .p-datatable-wrapper {
    height: calc(100vh - 270px);
  }
}

.checkbox-common {
  align-items: center;

  .p-checkbox-box {
    width: 18px;
    height: 18px;
  }
}

.p-listbox {
  .p-listbox-header {
    padding: 10px;
    background: transparent;

    .p-listbox-filter {
      padding: 5px 10px;
      font-size: 14px;

      &:focus,
      &:hover {
        box-shadow: none;
        border-color: variable.$second-green-color;
      }
    }
  }
}

.listbox-common {
  padding: 0;
  margin: 0;
  width: 100%;
  border: 0;

  .p-listbox-list {
    padding: 0.5rem 0rem;
    font-size: 14px;
    column-width: 200px;
    overflow: hidden;

    .p-listbox-item {
      padding: 0.2rem;

      &:nth-child(11) {
        break-after: column;
      }

      &:focus {
        box-shadow: none;
      }

      &.p-highlight {
        color: variable.$second-green-color;
        // background-color: variable.$background-color-white-smoke;
      }
    }
  }
}

.helper-text {
  font-size: 12px;
  font-weight: 500;
  color: variable.$blur-shadow-color;
  margin-top: 3px;

  .fa-solid {
    font-size: 13px;
  }

  .helper-bold {
    color: variable.$light-green-color;
  }
}

.error-text {
  font-size: 12px;
  font-weight: 500;
  color: variable.$red-error;
  margin-top: 3px;
  text-transform: uppercase;

  .fa-solid {
    font-size: 13px;
  }

  .helper-bold {
    color: variable.$light-green-color;
  }
}

.common-horizontal-layout-header {
  .wi-search-container {
    padding: 5px 0;

    .p-inputtext {
      height: 30px;
      font-size: 13px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .pi-search {
      font-size: 15px;
    }
  }

  .btn-create {
    margin: 5px 0px;
    font-family: variable.$font-name-Sora;

    .wi-btn-label {
      font-family: variable.$font-name-Sora;
    }

    .wi-button {
      min-height: 30px;
      height: 30px;
      font-size: 13px;
    }
  }
}

.p-dialog-header-close.p-link {
  &:focus {
    box-shadow: none !important;
  }
}

.p-menu {
  .p-menuitem-link {
    &:focus {
      box-shadow: none !important;
    }

    &.wi-menuitem-3-dot {
      padding: 0.5rem 0.75rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &.p-disabled {
        cursor: not-allowed;
        .p-menuitem-icon {
          color: variable.$description-color;
        }

        .p-menuitem-containers {
          .p-menuitem-text.description,
          .p-menuitem-text.headline {
            color: variable.$description-color;
          }
        }
      }

      &:not(.p-disabled):hover .p-menuitem-containers {
        .p-menuitem-text.description,
        .p-menuitem-text.headline {
          color: variable.$description-color;
        }
      }

      .p-menuitem-icon {
        width: 18px;
        color: variable.$second-green-color;
      }

      .p-menuitem-containers {
        display: flex;
        flex-direction: column;

        .p-menuitem-text.headline {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.25;
          color: variable.$second-green-color;
        }

        .p-menuitem-text.description {
          font-size: 12px;
          font-weight: 400;
          line-height: 1.25;
          color: variable.$coupon-3-dot-description-color;
          margin-top: 5px;
        }
      }
    }
  }
}

.p-dropdown-common {
  .p-dropdown,
  .p-multiselect {
    width: 100%;
    height: 42px;
    border-radius: 16px;
    position: relative;

    .p-dropdown-label,
    .p-multiselect-label {
      display: flex;
      align-items: center;
      color: variable.$second-green-color;
      font-size: 14px;
      height: 100%;
    }

    &:focus-visible {
      box-shadow: none;
      outline: none;
      border: 1px solid variable.$second-green-color !important;
    }

    .p-dropdown-trigger,
    .p-multiselect-trigger {
      transition: 0.16s ease-in-out all;
      .p-dropdown-trigger-icon,
      .p-multiselect-trigger-icon {
        transform: rotate(0deg);
        transition: 0.16s ease-in-out all;
        color: variable.$second-green-color;
      }
    }

    .p-dropdown-panel,
    .p-multiselect-panel {
      border-radius: 16px;
      box-shadow: none !important;

      .p-dropdown-header,
      .p-multiselect-header {
        padding: 10px !important;
        background: #f3f3f3 !important;
        background-color: transparent;
        border-radius: 5px 5px 0px 0px !important;
        border-bottom-width: 0 !important;
        border: 1px solid variable.$second-green-color;

        .p-dropdown-filter,
        .p-multiselect-filter {
          border-radius: 5px !important;
        }

        ~ .p-dropdown-items-wrapper,
        ~ .p-multiselect-items-wrapper {
          border-radius: 0px 0px 5px 5px !important;
        }

        .p-dropdown-filter-container input,
        .p-multiselect-filter-container input {
          height: 32px;
          font-size: 16px;
          box-shadow: none;
          border: 1px solid variable.$second-green-color !important;
        }
      }

      .p-dropdown-items-wrapper,
      .p-multiselect-items-wrapper {
        border: 1px solid variable.$second-green-color;
        border-radius: 6px;
        overflow-y: auto;
        max-height: 210px;

        .p-dropdown-item,
        .p-multiselect-item {
          font-weight: 600;
          padding: 5px 16px;
          font-size: 12px;
          color: variable.$second-green-color;
          text-transform: none;
          cursor: pointer;
        }

        .p-dropdown-item.p-highlight,
        .p-multiselect-item.p-highlight {
          background: variable.$background-color-white-gray;
          color: variable.$primary-green-color !important;
        }
      }
    }
  }

  .p-multiselect:not(.p-disabled):hover,
  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    outline: none;
    border: 1px solid variable.$second-green-color !important;
  }

  .p-multiselect:not(.p-disabled).p-focus {
    .p-multiselect-trigger {
      transition: 0.16s ease-in-out all;
      .p-multiselect-trigger-icon {
        transform: rotate(180deg);
        transition: 0.16s ease-in-out all;
      }
    }
  }

  .p-dropdown:not(.p-disabled):hover,
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    outline: none;
    border: 1px solid variable.$second-green-color !important;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    .p-dropdown-trigger {
      transition: 0.16s ease-in-out all;
      .p-dropdown-trigger-icon {
        transform: rotate(180deg);
        transition: 0.16s ease-in-out all;
      }
    }
  }
}

.wi-menu-button-action {
  .menu-button {
    height: 30px;
    font-size: 11.5px;
    border-radius: 20px;
    background: variable.$primary-green-color;
    border: solid 1px white;
    box-shadow: none;
    padding: 5px 15px;
    margin: 4px 0px 0px 4px;
    .p-button-label {
      font-family: Sora;
    }

    &:enabled:hover {
      border-color: variable.$second-yellow-color;
      color: variable.$second-green-color;
      background: variable.$second-yellow-color;
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .fa-ellipsis {
    border-radius: 50px;
    padding: 7px 8px;
    font-size: 13px;

    &:hover {
      background: variable.$color-darkgray;
    }
  }
}

.p-sidebar {
  .p-sidebar-header {
    padding: 15px 20px;
  }
}

.p-dropdown-custom-search.p-dropdown {
  .p-dropdown-panel {
    .p-dropdown-header {
      border-radius: 5px 5px 0px 0px !important;
      border: 1px solid variable.$second-green-color;
      border-bottom-width: 0 !important;
      padding: 10px !important;
      background: #f3f3f3 !important;

      .p-dropdown-filter {
        border-radius: 5px !important;
      }

      ~ .p-dropdown-items-wrapper {
        border-radius: 0px 0px 5px 5px !important;
      }
    }
  }
}
