@import '../../../utils/style/style-common.scss';

.listbox-container {
  .list-boxs {
    display: flex;
    .p-checkbox-label {
      font-size: 13px;
      margin-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 230px;
      overflow: hidden;
    }
  }

  .p-listbox.p-component {
    @extend .listbox-common;
  }

  .p-checkbox {
    @extend .checkbox-common;
  }

  .p-listbox.p-component .p-listbox-list .p-listbox-item.p-highlight {
    background-color: transparent;
  }
}
