@use '../../utils/variable.scss' as variable;

.page-details {
  justify-content: space-evenly;

  .page-form-details {
    // width: 580px;
    max-width: 550px;

    .p-accordion-tab-active {
      height: auto;
    }

    .element-form input,
    .element-form .p-inputtext {
      font-size: 16px;
    }
  }

  .custom-page-form-details {
    @extend .page-form-details;

    height: calc(100vh - 235px);

    .p-accordion-tab-active {
      height: calc(100vh - 235px);
    }
  }

  .page-preview {
    flex: 1;
    min-width: 600px;

    .preview-action-panel {
      background: variable.$second-green-color;
      max-height: 63px;
      min-height: 63px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .zoom-level-container,
      .lang-mode,
      .screen-mode {
        width: 33.33%;
        padding: 13px;

        .mode-item {
          padding: 8px;
          font-size: 1.2rem;
          border-radius: 5px;
          color: variable.$text-color-white;
          cursor: pointer;

          &.btn-disabled {
            color: variable.$gray-color;
          }

          &.mode-active {
            color: variable.$second-yellow-color;
          }
        }

        .lang-item {
          padding: 2px 6px 6px;
          border-radius: 4px;
          cursor: pointer;
          filter: grayscale(1);

          &.mode-active {
            filter: grayscale(0);
          }
          .icon-lang {
            width: 30px;
            border-radius: 3px;
          }

          &:not(.mode-active) {
            filter: grayscale(100%);
          }
        }
      }

      .screen-mode {
        text-align: left;
      }
    }

    .page-preview-content {
      background-color: variable.$background-color-white-smoke;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 312px);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .preview-screen {
      // margin: auto !important;
      height: 100%;
    }

    .preview-screen-desktop {
      width: 1260px !important;
    }

    .preview-screen-tablet {
      width: 700px !important;
    }

    .preview-screen-mobile {
      width: 400px !important;
    }

    .loading-iframe-component {
      display: flex;
      width: 100%;
      height: calc(100vh - 190px);
      align-items: center;
      justify-content: center;
      background-color: variable.$background-color-white-smoke;
      .p-progress-spinner-circle {
        stroke: variable.$second-green-color !important;
      }
    }
  }
}

.page-details-header {
  font-size: 24px;
  text-align: left;
  margin-bottom: 10px;
  display: flex;

  .page-status {
    font-size: 12px;
    text-transform: uppercase;
    background: variable.$second-green-color;
    width: 100px;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto 10px;
    color: variable.$text-color-white;
    font-weight: 700;

    &-published {
      background-color: variable.$second-yellow-color;
      color: variable.$second-green-color;
    }
  }

  .page-details-header-title {
    flex: 1;

    .page-type {
      > span {
        font-size: 13px;
        // background: #13735f;
        color: variable.$light-grey-color;
        // font-weight: 700;
        // padding: 5px 15px;
        border-radius: 20px;
      }
    }
  }

  .btn-submit-container {
    .btn-submit-page {
      &.p-button {
        background: transparent;
        border-color: variable.$second-green-color;
        color: variable.$second-green-color;
        padding: 7px 20px;
        margin-left: 10px;
        height: 36px;
        font-size: 13px;
        text-decoration: none;
        font-weight: 700;

        .p-button-icon {
          font-size: 15px;
        }

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: variable.$second-green-color;
          border-color: variable.$second-green-color;
          color: variable.$white;
        }

        &.p-disabled {
          border-color: #e5e5e5;
          background-color: #e5e5e5;
          color: variable.$text-color-white;
          cursor: default;
        }
      }

      &.btn-publish {
        background-color: variable.$second-green-color;
        border-color: variable.$second-green-color;
        color: variable.$white;

        &:hover {
          background-color: variable.$second-yellow-color;
          border-color: variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }
  }
}
