@use '../../../utils/variable.scss' as variable;

.dot-action {
  .fa-ellipsis {
    border-radius: 50px;
    padding: 7px 8px;
    font-size: 13px;

    &:hover {
      background: variable.$color-darkgray;
    }
  }
}

.dot-action-menu {
  width: max-content;
  &.p-menu {
    .color-red-warning {
      .p-menuitem-text.headline,
      .p-menuitem-icon {
        color: variable.$red-icon !important;
      }
    }

    .p-menuitem-text {
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .menu-item-hidden {
    display: none;
  }
}
