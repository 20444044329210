.edit-basic-information {
  .partner-logo-img {
    .label-upload-field-v2 {
      height: 264px;
      .media-preview {
        object-fit: contain !important;
      }
    }
  }
}
