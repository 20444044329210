@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.add-wir-form {
  width: 100%;
  height: 100%;
  .add-wir-form-container {
    @extend .form-content;

    .row-content {
      .wir_logo {
        margin: 0;
        padding: 0;

        &.logo-form {
          height: 132px !important;
          min-height: 132px !important;
          margin-left: 0 !important;

          .image-cert {
            width: 100%;
            height: 130px;
            object-fit: cover;
            object-position: 50% 50%;
            border-radius: 8px;
          }
        }
      }

      .global-page-item {
        display: flex;

        .global-page-picture {
          height: 70px;
          width: 100px;
          min-width: 100px;
          border-radius: 20px;
          background: #a9a9a914;

          > img {
            height: 70px;
            width: 100px;
            border-radius: 20px;
            background: #a9a9a914;
            object-fit: cover;
            object-position: center;
          }
        }

        .page-details {
          padding: 0 10px;

          .global-page-name {
            font-weight: 700;
            font-size: 14px;
            text-overflow: ellipsis;
            width: 300px;
            white-space: nowrap;
            overflow: hidden !important;
          }

          .global-page-position {
            color: variable.$light-slate-gray;
            font-size: 12px;
            width: 300px;
          }
        }
      }

      .btn-submit {
        display: flex;
        justify-content: flex-end;
        button {
          border-color: transparent;
          background-color: variable.$primary-green-color !important;
          color: variable.$second-yellow-color !important;
          margin-left: 1rem;
          padding: 0.5rem 1.5rem;
          min-height: 2rem;
          height: 2rem;
          font-family: variable.$font-name-Sora;
          font-size: 14px;

          &:hover {
            background-color: variable.$second-yellow-color !important;
            color: variable.$primary-green-color !important;
            border-color: variable.$second-yellow-color !important;
          }
        }
      }

      input,
      textarea {
        color: variable.$primary-green-color !important;
      }

      label {
        font-size: 14px !important;
        text-transform: unset !important;

        .required-label {
          color: variable.$red-error;
        }

        .lowercase-label {
          text-transform: lowercase;
        }
      }

      .row {
        .error-existing {
          display: flex;
          align-items: center;
        }

        .calendar-item {
          position: relative;
          .p-calendar {
            input {
              border-radius: 1rem;
              color: variable.$primary-green-color;
              z-index: 2;
              &:focus {
                border-right: unset !important;
              }
            }
          }

          .p-button.p-datepicker-trigger {
            height: 40px;
            border: none;
            width: 20px;
            position: absolute;
            right: 1rem;
            z-index: 2;
            top: 1px;
            color: variable.$primary-green-color;

            &:focus {
              border: none;
            }
          }
        }

        .check-box-container {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          label {
            color: variable.$light-slate-gray;
            font-size: 14px;
            font-style: italic;

            svg {
              width: 20px !important;
            }
          }
        }

        .error-existing-donor {
          @extend .error;

          padding: unset;
          &::before {
            display: none;
          }
        }
        .add-wir-submit-component {
          display: flex;
          justify-content: flex-end;
          // padding-top: 1rem;

          button {
            border-color: transparent;
            background-color: variable.$primary-green-color !important;
            color: variable.$second-yellow-color !important;
            margin-left: 1rem;
            padding: 0.5rem 1.5rem;
            min-height: 2rem;
            height: 2rem;
            font-family: variable.$font-name-Sora;
            font-size: 14px;

            &:hover {
              background-color: variable.$second-yellow-color !important;
              color: variable.$primary-green-color !important;
              border-color: variable.$second-yellow-color !important;
            }
          }
        }
      }
    }
  }
}

.add-wir-dropdown-panel.p-dropdown-panel {
  margin-top: 2px;
  width: 210px;
  border: 1px solid variable.$second-green-color;
  box-shadow: none;
  z-index: 99999 !important;

  .p-dropdown-header {
    padding: 0.2rem 0.75rem;
    margin: 0;
    background-color: transparent;
    border-width: 0;
    .p-dropdown-filter-container input {
      height: 30px;
      font-size: 12px;
      padding: 7px 12px 9px;
      font-weight: 500;
      color: variable.$primary-green-color;
      box-shadow: none;
      border: 1px solid variable.$second-green-color !important;
    }
  }

  .p-dropdown-items-wrapper {
    border-width: 0;
    .p-dropdown-item {
      color: variable.$primary-green-color;
      font-family: 'Lato';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 5px 16px;
      text-transform: none;
      cursor: pointer;
    }
  }
}
