@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.dialog-export-container {
  .label-checkbox {
    font-size: 14px;
  }
}

.p-dialog.wi-export-dialog .p-dialog-content {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
