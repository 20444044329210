@use '../../../../utils/variable.scss' as variable;

.coupon-detail-import-container {
  margin-top: -30px;
  display: flex;
  justify-content: center;
  width: 100%;

  .column-message {
    font-size: 13px;
    padding: 0 20px !important;
  }

  .import-btn {
    width: 40%;
    min-width: 540px;
    padding: 0px;
    border-radius: 20px;

    .input-upload {
      display: none;
    }

    form {
      overflow: hidden;
    }

    .upload-file {
      padding-top: 20px;
      .label-upload-csv {
        width: 100%;
        padding: 0.5rem;
        font-size: 0.9375rem;
        font-weight: 600;
        // padding-left: 30px;
        font-style: normal;
        background-color: variable.$second-green-color;
        color: variable.$text-color-white;
        border-radius: 20px;
        cursor: pointer;
      }

      .name-file {
        padding-left: 0px;
        font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        max-width: 280px;
        color: variable.$second-green-color;
      }
    }

    .note-message {
      padding: 0;
      margin: 0.25rem 1rem;

      p {
        padding: 0;
        margin: 0;
        color: variable.$red-color;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
