@use '../../../utils/variable.scss' as variable;

.checkbox-container {
  margin: 12px 0;
  display: inline-block;
  position: relative;

  label {
    padding-right: 8px;
    &.required:after {
      content: '*';
      position: absolute;
      right: 0;
      top: 0;
      color: variable.$second-green-color;
    }
  }

  input[type='checkbox'] {
    opacity: 0 !important;
    pointer-events: none;
    position: absolute;
    top: 3px;
    left: 3px;

    + label {
      cursor: pointer;
      position: relative;
      font-size: 15;
      display: inline-block;
      padding-left: 24px;

      svg {
        width: 1.625rem;
        stroke-width: 5px;
        fill: transparent;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;

        .box {
          stroke-dasharray: 320;
          stroke-dashoffset: 0;
          stroke: variable.$default-color-text;
          fill: transparent;
          transition: stroke-dashoffset 0.3s linear;
        }

        .box-checked {
          stroke-dasharray: 320;
          stroke-dashoffset: 320;
          stroke: variable.$second-green-color;
          fill: transparent;
          transition: stroke-dashoffset 0.3s linear 0.3s;
        }

        .check {
          stroke-dasharray: 70;
          stroke-dashoffset: 70;
          stroke: variable.$second-green-color;
          stroke-width: 8;
          fill: none;
          transition: stroke-dashoffset 0.3s linear;
        }
      }

      span {
        padding-top: 1px;
        margin-left: 8px;
      }
    }

    &:checked + label {
      .box {
        stroke-dashoffset: 320;
      }

      .box-checked {
        stroke-dashoffset: 0;
      }

      .check {
        stroke-dashoffset: 0;
      }
    }
    &:focus {
      + label {
        svg {
          stroke-width: 8;
        }
      }
    }
  }
  .error {
    position: relative;
    color: variable.$red-color;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.3px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 0 0 24px;

    &:before {
      display: block;
      content: url('../../../assets/images/errorIcon.svg');
      position: absolute;
      left: 0;
      top: 8px;
      width: 18px;
      height: 18px;
    }
  }
}
