@use '../../../utils/variable.scss' as variable;

.dot-action-v2 {
  padding: 8px;
  background: variable.$background-header;
  border-radius: calc(variable.$primary-element-height / 2);
  min-width: variable.$primary-element-height;
  min-height: variable.$primary-element-height;
  cursor: pointer;

  &.menu-action-v2 {
    background: variable.$transparent;
    padding: 0px;
  }

  &:hover {
    background: variable.$color-disabled;

    &.menu-action-v2 {
      background: variable.$transparent;
    }
  }

  .icon-svg {
    width: 24px;
    height: 24px;
  }
}

.dot-action-menu-v2 {
  border-radius: 20px !important;
  box-shadow: variable.$primary-box-shadow !important;
  padding: 0 !important;
  overflow: hidden;
  min-width: 280px;
  &.p-menu {
    min-width: 290px;
    .color-red-warning {
      .p-menuitem-text.headline,
      .p-menuitem-icon {
        color: variable.$red-icon !important;
      }
    }

    .p-menuitem:last-child {
      .wi-menuitem-3-dot-v2 {
        border-bottom: none;
      }
    }

    .p-menuitem-text {
      font-size: variable.$text-default-size;
      font-family: 'Poppins', sans-serif;
      color: variable.$black !important;
    }

    .p-menuitem-link {
      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        background: variable.$background-header;
      }

      &.p-disabled {
        opacity: 0.2 !important;
      }

      &.wi-menuitem-3-dot-v2 {
        min-width: 250px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f3f2f8;
        gap: 24px;
        height: 56px;

        // &.p-disabled {
        //     cursor: not-allowed;
        //     .p-menuitem-icon {
        //         color: variable.$description-color;
        //     }

        //     .p-menuitem-containers {
        //         .p-menuitem-text.description,
        //         .p-menuitem-text.headline {
        //             color: variable.$description-color;
        //         }
        //     }
        // }

        &:not(.p-disabled):hover .p-menuitem-containers {
          .p-menuitem-text.description,
          .p-menuitem-text.headline {
            color: variable.$black;
          }
        }

        .p-menuitem-icon {
          width: 18px;
          margin-left: 8px;
          margin-right: 0;
        }

        .p-menuitem-containers {
          display: flex;
          flex-direction: column;

          &.wi-active {
            .p-menuitem-text {
              color: variable.$color-light-green !important;
            }
          }

          &.wi-danger {
            .p-menuitem-text {
              color: variable.$danger-color !important;
            }
          }

          &.wi-inactive {
            .p-menuitem-text {
              color: variable.$color-orange !important;
            }
          }

          &.wi-archive {
            .p-menuitem-text {
              color: variable.$color-gray !important;
            }
          }

          &.wi-complete {
            .p-menuitem-text {
              color: variable.$color-teal !important;
            }
          }

          .p-menuitem-text.headline {
            font-size: variable.$text-default-size;
            line-height: 16px;
            font-family: 'Poppins', sans-serif;
          }

          .p-menuitem-text.description {
            font-size: variable.$text-min-size;
            font-weight: 400;
            line-height: 16px;
            color: variable.$coupon-3-dot-description-color;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
