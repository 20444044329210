@use '../../../../utils/variable.scss' as variable;
@import '../../../../styles/style-common-v2.scss';

.land-form {
  width: 100%;
  height: calc(100vh - 84px - 73px);
  overflow-y: auto;

  .land-form-container {
    width: 552px;
    margin: auto;

    .row-content {
      @extend .row-common;

      .land-text {
        font-family: 'Poppins', sans-serif;
        color: variable.$black;
        font-size: variable.$text-default-size;
        font-weight: 400;
        text-align: left;
        &.label {
          font-size: variable.$text-h2-size;
          font-weight: 700;
        }
      }

      .p-inputtextarea {
        height: 300px !important;
        resize: none;
      }
    }
  }
}
