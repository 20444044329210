@use '../../utils/variable.scss' as variable;

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,300italic,400italic,700');
@import url('https://fonts.googleapis.com/css?family=Sora:300,400,300italic,400italic,700');

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font:
    112.5%/1.45em georgia,
    serif,
    sans-serif;
  box-sizing: border-box;
  // overflow-y: scroll;
  overflow: hidden;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: hsla(0, 0%, 0%, 0.8);
  font-family: Lato, Sora, Arial, Helvetica, sans-serif;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-size: variable.$text-default-size;
  color: variable.$text-default-color;

  *:not(.pi) {
    font-family: Lato, Sora, Arial, Helvetica, sans-serif;
  }

  .fa-solid,
  .fas {
    font-family: 'Font Awesome 6 Free' !important;
  }

  // ::-webkit-scrollbar {
  //   width: 6px;
  //   height: 6px;
  // }

  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 2px variable.$light-green-row;
  //   background: variable.$light-green-row;
  //   box-shadow: none;
  //   background: transparent;
  //   border-radius: 8px;
  // }

  // ::-webkit-scrollbar-thumb {
  //   background-color: variable.$background-color-green;
  //   border-radius: 10px;
  //   // opacity: 0.1;

  //   &:hover {
  //     background-color: variable.$second-green-color;
  //   }
  // }
}

.p-datatable .p-datatable-tbody > tr {
  color: variable.$default-color-text;
}

.p-progressbar {
  background: variable.$light-grey-color;
  border-radius: 5px;

  .p-progressbar-value-animate {
    background: variable.$second-green-color !important;
  }

  .p-progressbar-label {
    font-size: 12px;
    color: variable.$text-color-white;
  }
}

.layout {
  .layout-container {
    margin: 0 auto;
    width: 100%;
    height: 100vh;

    .layput-content {
      width: 100%;
      height: 100%;

      .tox-editor-container {
        .tox-editor-header {
          z-index: 1 !important;
        }
      }

      .menu-items {
        background: variable.$second-green-color;

        &.vertical {
          min-width: 210px;
          max-width: 210px;
          transition: all 0.3s;
        }

        .menu-footer {
          width: 400px;
          margin-left: -80px;
        }

        &.leaf-mode.vertical {
          > .navbar-logo {
            padding: 20px 60px 0 60px;
            padding-bottom: 0;
            width: 100%;
            height: 75px;
            overflow: hidden;
          }

          .p-menu {
            background: variable.$second-green-color;
            border: none;
            // background: url(stati);

            .wi-menu-item {
              border-top-left-radius: 25px;
              border-bottom-left-radius: 25px;
              margin-left: 10px;

              .p-menuitem-icon,
              .p-menuitem-text {
                color: variable.$text-color-white;
                font-size: 14px;
              }

              &:hover,
              .p-menuitem-link:not(.p-disabled):hover {
                background: variable.$light-green-row;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
                .p-menuitem-icon,
                .p-menuitem-text {
                  color: variable.$second-green-color;
                }
              }
            }

            .wi-menu-item-active {
              background: variable.$text-color-white;

              .p-menuitem-icon {
                color: variable.$second-green-color;
              }

              .p-menuitem-text {
                color: variable.$second-green-color;
                font-weight: 700;
              }
            }
          }
        }
      }

      .vertical.menu-items.active {
        min-width: 90px;
        max-width: 90px;

        .p-menu ul li a .p-menuitem-icon {
          text-align: center;
        }

        .p-menu ul li a .p-menuitem-text {
          display: none;
        }

        &.leaf-mode {
          .navbar-logo {
            transform: scale(0.85, 0.5);
            padding: 0;
          }
        }
      }

      .main-content {
        position: relative;
        overflow: auto;
        transition: all 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.vertical {
          padding: 20px 30px 0 30px;
          width: calc(100% - 210px);
          height: 100%;

          &.active {
            width: calc(100% - 90px);
          }

          .card.page-wrapper {
            margin: -20px -30px 0px 0px;
            padding: 0;
          }
        }

        &.horizontal {
          padding: 0 30px;
          padding-top: 0.5rem;
          width: 100%;
          height: calc(100vh - 100px);
          max-width: 1690px;
          margin: auto;

          .card.page-wrapper {
            margin: 0;
            padding: 0;
            margin-top: -0.5rem;
          }
        }

        &.fullscreen {
          width: 100% !important;
          padding: 0px;
        }

        &.fullscreen-horizontal {
          width: 100% !important;
          padding: 0px;
          height: calc(100vh - 100px);
        }
      }

      .custom-main-content {
        @extend .main-content;
        padding-top: 0 !important;
      }

      .navbar-btn {
        box-shadow: none;
        outline: none !important;
        border: none;
        color: variable.$text-color-white;
        &:hover {
          background-color: variable.$second-green-color;
          color: variable.$light-slate-gray;
        }
      }
    }
  }
}

// @media screen and (max-width: 720px) {
//   .layout {
//     .layout-container {
//       .layput-content {
//         .menu-items {
//           display: none;
//         }

//         .main-content {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

.p-selectbutton {
  .p-button {
    padding: 12px 20px !important;

    .p-button-label {
      font-size: 13px !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.p-highlight {
      border-color: variable.$second-green-color !important;
      background-color: variable.$second-green-color !important;

      &:hover {
        border-color: variable.$second-green-color !important;
        background-color: variable.$second-green-color !important;
      }
    }
  }
}

.p-paginator {
  padding: 5px 0 !important;
  height: 50px;

  > * {
    height: 30px !important;
  }

  > .p-button {
    padding: 0 !important;
    width: 30px !important;
    min-width: 30px !important;
  }

  .p-paginator-current {
    font-size: 15px;
  }

  .p-dropdown {
    height: 2rem !important;

    .p-dropdown-label {
      padding: 5px 0 5px 10px;
      font-size: 15px;
    }
  }

  .p-paginator-pages {
    height: 40px !important;
    padding-top: 3px;
  }

  .p-paginator-pages .p-paginator-page {
    height: 30px;
    min-width: 30px;
    font-size: 13px;
  }
}

/* Small devices (portrait tablets and large phones, 681.98px and up) */
@media only screen and (max-width: 681.98px) {
  .layout > .layout-container > .layput-content > .main-content.horizontal {
    .table-history {
      height: calc(100vh - 368px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 368px);
      }
    }

    .table-donation {
      height: calc(100vh - 488px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 488px);
      }
    }

    .table-mailing-detail {
      height: calc(100vh - 320px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 320px);
      }
    }

    .table-region {
      height: calc(100vh - 376px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 376px);
      }
    }

    .table-land,
    .table-campaign {
      height: calc(100vh - 318px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 318px);
      }
    }

    .table-coupon {
      height: calc(100vh - 326px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 326px);
      }
    }

    .table-wilderness-runs {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }
  }
}

/* Medium devices (landscape tablets, 682px and up) */
@media only screen and (min-width: 682px) {
  .layout > .layout-container > .layput-content > .main-content.horizontal {
    .table-history {
      height: calc(100vh - 342px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 342px);
      }
    }

    .table-donation {
      height: calc(100vh - 388px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 388px);
      }
    }

    .table-mailing-detail {
      height: calc(100vh - 284px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 284px);
      }
    }

    .table-region {
      height: calc(100vh - 344px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 344px);
      }
    }

    .table-land,
    .table-campaign {
      height: calc(100vh - 286px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 286px);
      }
    }

    .table-coupon {
      height: calc(100vh - 296px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 296px);
      }
    }

    .table-code {
      height: calc(100vh - 522px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 522px);
      }
    }

    .table-wilderness-runs {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }

    .table-goal {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }

    .table-pages {
      height: calc(100vh - 392px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 392px);
      }
    }
  }

  .layout > .layout-container > .layput-content > .custom-main-content.horizontal .donation-detail .header-container {
    padding-top: 10px;
  }
}

/* Large devices (laptops/desktops, 767.98px and up) */
@media only screen and (min-width: 767.98px) {
  .layout > .layout-container > .layput-content > .main-content.horizontal {
    .table-history {
      height: calc(100vh - 286px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 286px);
      }
    }

    .table-donation {
      height: calc(100vh - 348px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 348px);
      }
    }

    .table-import {
      height: calc(100vh - 318px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 318px);
      }
    }

    .table-mailing-detail {
      height: calc(100vh - 286px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 286px);
      }
    }

    .table-region,
    .table-land,
    .table-campaign {
      height: calc(100vh - 288px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 288px);
      }
    }

    .table-wilderness-runs {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }

    .table-goal {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }

    .table-headers,
    .table-footers {
      height: calc(100vh - 254px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 254px);
      }
    }

    .table-coupon {
      height: calc(100vh - 298px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 298px);
      }
    }

    .table-code {
      height: calc(100vh - 478px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 478px);
      }
    }

    .table-pages {
      height: calc(100vh - 328px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 328px);
      }
    }

    .table-partners {
      height: calc(100vh - 280px) !important;
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 280px) !important;
      }
    }

    .table-donation-example,
    .table-contact,
    .table-faq,
    .table-partner-type {
      height: calc(100vh - 280px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 280px);
      }
    }

    .table-quotes {
      height: calc(100vh - 292px);
      .p-datatable-wrapper {
        height: calc(100vh - 292px) !important;
      }
    }

    .table-receipt {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .page-details .custom-page-form-details {
      height: calc(100vh - 200px);

      .p-accordion-tab-active {
        height: calc(100vh - 298px);

        .p-toggleable-content .p-accordion-content {
          height: calc(100vh - 340px);
        }
      }
    }

    .builder-page .builder-components {
      height: calc(100vh - 210px);
    }
  }
}

/* Large devices (laptops/desktops, 1023.98px and up) */
@media only screen and (min-width: 1023.98px) {
  .layout > .layout-container > .layput-content > .main-content.horizontal {
    .table-land,
    .table-campaign,
    .table-region,
    .table-mailing-detail,
    .table-history {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .table-donation {
      height: calc(100vh - 258px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 258px);
      }
    }

    .table-coupon {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .table-code {
      height: calc(100vh - 358px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 358px);
      }
    }

    .table-pages {
      height: calc(100vh - 278px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 278px);
      }
    }
  }
}

/* Extra large devices (large laptops and desktops, 1280px and up) */
@media only screen and (min-width: 1280px) {
  .layout > .layout-container > .layput-content > .main-content.horizontal {
    .table-land,
    .table-campaign,
    .table-region,
    .table-mailing-detail,
    .table-history {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .table-donation {
      height: calc(100vh - 276px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 276px);
      }
    }

    .table-import {
      height: calc(100vh - 284px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 284px);
      }
    }

    .table-coupon {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .table-code {
      height: calc(100vh - 358px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 358px);
      }
    }

    .table-pages {
      height: calc(100vh - 278px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 278px);
      }
    }

    .table-donation-example,
    .table-contact,
    .table-faq,
    .table-partners {
      height: calc(100vh - 280px) !important;
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 280px) !important;
      }
    }

    .table-receipt {
      height: calc(100vh - 238px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 238px);
      }
    }

    .table-wilderness-runs {
      height: calc(100vh - 230px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 230px);
      }
    }

    .table-goal {
      height: calc(100vh - 230px);
      .p-datatable .p-datatable-wrapper {
        height: calc(100vh - 230px);
      }
    }

    .page-details .page-form-details {
      height: calc(100vh - 180px);

      .p-accordion-tab-active {
        height: calc(100vh - 270px);

        .p-toggleable-content .p-accordion-content {
          height: calc(100vh - 320px);
        }
      }
    }

    .page-details .custom-page-form-details {
      height: calc(100vh - 210px);
    }

    .builder-page .builder-components {
      height: calc(100vh - 178px);
    }
  }
}

*:focus:not(div),
*:hover:not(div) {
  // box-shadow: none!important;
  outline-color: transparent !important;
}

.wi-dialog.p-dialog {
  .p-dialog-header {
    background-color: variable.$white;
    color: rgb(52, 58, 64);
    padding: 10px 20px;
    border-bottom: 0.5px solid #dcdcdc;

    .p-dialog-title {
      font-size: 20px;
    }

    .p-dialog-header-close {
      color: rgb(52, 58, 64);
    }
  }

  .p-dialog-content {
    padding: 10px 20px !important;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .form-mode form {
      padding-top: 0px !important;

      .img-frame-1-1 {
        width: 150px;
        height: 150px;
      }

      .editor-multilines .tox-tinymce {
        height: 200px !important;
      }
    }

    .wi-button {
      padding: 7px 15px;
      font-size: 13px;
      min-height: 36px;
      font-family: 'Sora';

      .p-button-icon {
        font-size: 15px;
      }
    }
  }

  .footer-panel {
    .p-button {
      padding: 6px 20px;
      font-size: 14px;
      background-color: variable.$second-green-color !important;
      border-color: variable.$second-green-color !important;
      color: variable.$white !important;

      .p-button-icon {
        font-size: 14px;
      }

      &:hover {
        background-color: variable.$second-yellow-color !important;
        border-color: variable.$second-yellow-color !important;
        color: variable.$second-green-color !important;
      }
    }
  }
}

.wi-selectbutton.p-selectbutton {
  height: 40px;
  left: 0px;
  top: 0px;
  border: 1px solid #004432;
  box-sizing: border-box;
  border-radius: 30px;
  position: relative;
  padding: 0 3px;
  display: inline-block;

  &.wi-selectbutton-tri {
    .p-button {
      width: calc(100% / 3) !important;
      border-radius: 30px;
      padding: 12px !important;
    }
  }

  .p-button {
    padding: 12px 20px;
    height: 32px;
    top: 3px;
    border: none;
    border-radius: 30px !important;
    animation: deselect_btn 60ms ease-in 1 reverse both;

    .p-button-label {
      font-size: 18px;
      font-family: Lato;
      font-style: normal;
      color: variable.$primary-green-color;
    }

    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      border-color: variable.$second-green-color !important;
      background-color: variable.$second-yellow-color !important;
      border: 1px solid variable.$second-yellow-color !important;
      border-radius: 30px;
      animation: select_btn 220ms ease-out 1 normal both;
      background-image: linear-gradient(90deg, variable.$second-yellow-color, variable.$second-yellow-color);
      background-size: 0;
      background-repeat: no-repeat;

      &:hover {
        border-color: variable.$second-yellow-color;
        background-color: variable.$second-yellow-color;
      }
    }
  }

  @keyframes select_btn {
    0% {
      transform: scale(0.2);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes deselect_btn {
    0% {
      background-size: 0 20%;
    }

    50% {
      background-size: 20% 50%;
    }

    to {
      background-size: 50% 100%;
    }
  }
}

.wi-static-form {
  .element-form {
    padding: 10px 0 !important;

    input {
      font-size: 14px;
      height: 42px !important;
    }
  }

  .checkbox-container {
    margin: 10px 0 !important;

    label {
      font-size: 14px;

      svg {
        width: 24px !important;
      }
    }
  }

  .btn-submit-container {
    .p-button {
      margin: 0px 0 10px 0 !important;
    }
  }
}

.error-text {
  color: variable.$red-icon;
  font-size: 12px;

  i {
    color: variable.$red-icon;
    font-size: 13px;
  }
}

.-p-button-label {
  font-family: Sora;
}

.p-progress-spinner {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 80px !important;
  background-image: url('../../assets/images/logo/green-eagle.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  // background: variable.$background-color-green;
}

.wi-country-dropdown {
  height: 40px;
  border-radius: 16px;

  &.p-inputwrapper-focus {
    border: 1px solid variable.$second-green-color;

    // &.p-focus {

    //   border-bottom-left-radius: 0px!important;
    //   border-bottom-right-radius: 0px!important;
    // }
  }

  .p-dropdown-label {
    font-size: 13px;
  }

  .p-dropdown-panel {
    .p-dropdown-header {
      border-left: 1px solid variable.$second-green-color;
      border-right: 1px solid variable.$second-green-color;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      padding: 15px 10px 10px 10px;
      background: variable.$white;
      margin-top: -7px;

      .p-dropdown-filter-container .p-dropdown-filter {
        border-radius: 20px;
        height: 36px;
      }
    }
  }
}

.p-dropdown-panel {
  z-index: 2701 !important;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: variable.$color-text-shadow; /* Màu nền mờ */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 4px solid variable.$second-green-color;
      border-top: 4px solid variable.$white;
      animation: spin 1s infinite linear; /* Hiệu ứng quay */
      // .image-loading {
      //   background-image: url('../../../public/images/green-eagle.svg');
      //   background-repeat: no-repeat;
      //   background-position: center;
      //   background-size: 60%;
      // }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.p-overlaypanel {
  &::before,
  &::after {
    display: none;
  }
}

.ml8 {
  margin-left: 8px;
}

.mr8 {
  margin-right: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mb16 {
  margin-bottom: 8px;
}

.p-inputswitch {
  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: variable.$second-green-color !important;
  }
}

.p-sidebar.global-blocks-dialog {
  .form-element {
    padding: 20px !important;
  }
}

.p-menu.transparent-menu {
  .p-menu-list {
    .p-menuitem.item-menu {
      font-size: 14px;
      .p-menuitem-link {
        display: grid;
        grid-template-areas: 'item2 item1';
        justify-content: flex-end;
        padding-right: 0;
        .p-menuitem-icon {
          grid-area: item1;
          padding-left: 1rem;
          margin-right: 0 !important;
          &::before {
            background-color: variable.$coupon-status-active;
            border-radius: 20px;
            padding: 10px;
            color: variable.$white;
            font-size: 16px;
          }
        }
        .p-menuitem-text {
          grid-area: item2;
          color: variable.$dark-gray;
          font-size: 12px;
          font-weight: 700;

          &:hover {
            color: variable.$main-blue;
          }
        }
      }

      .p-menuitem-link:not(.p-disabled):hover {
        background: transparent;
        .p-menuitem-icon {
          &::before {
            background-color: variable.$main-blue;
            color: variable.$white;
          }
        }
      }
    }
  }

  &.p-menu-overlay {
    box-shadow: none !important;
  }
}

.text-secondary-gray {
  color: variable.$text-secondary-color;
}

.text-highlight {
  color: variable.$text-highlight-color;
}

.wi-common-button {
  font-size: variable.$text-default-size;
  font-family: variable.$font-name-Sora;
  min-width: 110px;
  height: 30px;
  font-weight: 700;
  padding: 0 10px;
  align-self: center;
  align-items: center;
  border-radius: 20px;
  outline: none;
  box-shadow: none;
  width: max-content;
  text-decoration: none;
  display: flex;
  justify-content: center;

  .p-button-label,
  .wi-btn-label {
    font-weight: 700;
    font-family: variable.$font-name-Sora;
    text-decoration: none;
  }

  .p-button-icon {
    font-size: variable.$text-default-size;
    font-weight: 600;
  }

  &:focus,
  &:hover {
    box-shadow: none;
  }

  &.wi-button-medium {
    padding: 5px 20px;
    height: 35px;
  }
}

.p-button.wi-outline-button,
.wi-outline-button {
  color: variable.$second-green-color;
  background-color: variable.$white;
  border: 1px solid variable.$second-green-color;

  @extend .wi-common-button;

  &:enabled,
  &:not(.p-disabled) {
    &:hover {
      background: variable.$second-green-color;
      border-color: variable.$second-green-color;
      color: variable.$white;
      box-shadow: none;
    }
  }
}

.p-button.wi-primary-button {
  background-color: variable.$second-green-color;
  color: variable.$white;
  border: 1px solid variable.$second-green-color;

  @extend .wi-common-button;

  &:enabled,
  &:not(.p-disabled) {
    &:hover {
      background: variable.$second-yellow-color;
      border-color: variable.$second-yellow-color;
      color: variable.$second-green-color;
      box-shadow: none;
    }
  }
}

.p-button.wi-gold-button {
  background: variable.$second-yellow-color;
  color: variable.$second-green-color;
  border: 1px solid variable.$second-yellow-color;

  @extend .wi-common-button;

  &:enabled,
  &:not(.p-disabled) {
    &:hover {
      background-color: variable.$second-green-color;
      color: variable.$white;
      border: 1px solid variable.$second-green-color;
      box-shadow: none;
    }
  }
}

.p-button.wi-gray-button {
  color: variable.$gray-color;
  background-color: variable.$white;
  border: 1px solid variable.$gray-color;

  @extend .wi-common-button;

  &:enabled,
  &:not(.p-disabled) {
    &:hover {
      color: variable.$white;
      background-color: variable.$gray-color;
      border: 1px solid variable.$gray-color;
      box-shadow: none;
    }
  }
}

.p-button.wi-red-button {
  color: variable.$white;
  background-color: variable.$color-error;
  border: 1px solid variable.$color-error;

  @extend .wi-common-button;

  &:enabled,
  &:not(.p-disabled) {
    &:hover {
      color: variable.$color-error;
      background-color: variable.$white;
      border: 1px solid variable.$color-error;
      box-shadow: none;
    }
  }
}

.wi-form-title {
  font-size: variable.$text-default-size;
  color: variable.$text-secondary-color;
  line-height: normal;
}

.wi-form-displayed-value {
  color: variable.$black;
  font-size: variable.$text-default-size;

  &.disabled {
    pointer-events: none;
    text-decoration: none;
  }
}

a.wi-form-displayed-value {
  color: variable.$primary-color;
  font-size: variable.$text-default-size;
  font-weight: 700;

  &:hover {
    color: variable.$second-green-color;
  }
}

.wi-form-section-title.h3 {
  color: variable.$black;
  font-size: variable.$text-h3-size;
  font-weight: 700;
}

.p-tag.wi-status-tag {
  padding: 3px 5px;
  min-width: 80px;
  width: max-content;
  font-size: variable.$text-min-size;
  border-radius: 20px;

  .p-tag-value {
    text-transform: capitalize;
  }

  .p-tag-icon {
    display: none;
  }

  &.tag-status-archived {
    background: variable.$light-grey-color;
  }

  &.tag-status-active,
  &.tag-status-completed {
    background: variable.$status-succeeded;
  }

  &.tag-status-draft {
    background: variable.$status-pending;
  }

  &.tag-status-failed {
    background: variable.$status-failed;
  }

  &.tag-status-cancelled {
    background: variable.$status-cancelled;
  }

  &.tag-status-refunded {
    background: variable.$status-refunded;
  }

  &.tag-status-reserved {
    background: variable.$status-reserved;
  }

  &.tag-status-pending {
    background: #ab8acbc1;
  }

  &.tag-status-finished {
    background: #861135;
  }
}
