@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.add-goal-form {
  .add-goal-form-container {
    .row-content {
      .global-page-item {
        display: flex;

        .global-page-picture {
          height: 70px;
          width: 100px;
          min-width: 100px;
          border-radius: 20px;
          background: #a9a9a914;

          > img {
            height: 70px;
            width: 100px;
            border-radius: 20px;
            background: #a9a9a914;
            object-fit: cover;
            object-position: center;
          }
        }

        .page-details {
          padding: 0 10px;

          .global-page-name {
            font-weight: 700;
            font-size: 14px;
            text-overflow: ellipsis;
            width: 300px;
            white-space: nowrap;
            overflow: hidden !important;
          }

          .global-page-position {
            color: variable.$light-slate-gray;
            font-size: 12px;
            width: 300px;
          }
        }
      }
    }
  }
}
