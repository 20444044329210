@use '../utils/variable.scss' as variable;

.p-multiselect.wi-multiselect-v2 {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;

  &:focus,
  &:hover,
  &.p-focus {
    border: 0 !important;
    border-color: variable.$primary-color !important;
    box-shadow: 0px 0px 2px 0px variable.$primary-color !important;
  }

  &.p-disabled {
    background-color: variable.$bg-disabled-color;
    box-shadow: none;

    .p-multiselect-trigger-icon {
      color: variable.$icon-disabled !important;
    }
  }

  &:focus-visible {
    border: 0 !important;
  }

  &:focus-visible {
    border: 0 !important;
  }

  .p-multiselect-header {
    display: none;
  }

  .p-multiselect-label {
    color: variable.$black;
    display: flex;
    justify-content: flex-start;
    padding: 8px;
  }

  .p-multiselect-trigger {
    .p-multiselect-trigger-icon {
      color: variable.$black;
    }
  }

  .p-multiselect-panel {
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;

    .p-multiselect-item {
      font-size: variable.$text-default-size;
      padding: 16px 8px;
      font-family: 'Poppins', sans-serif;
      color: variable.$dark-gray;
      outline: none !important;
      box-shadow: none !important;

      &.p-highlight {
        background: variable.$background-header !important;
        color: variable.$dark-gray !important;
      }
    }
  }
}
