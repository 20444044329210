@use '../../../utils/variable.scss' as variable;

.wi-sidebar-component {
  .p-tree {
    border: 0;
    padding: 1.25rem 10px;
  }

  button,
  button:focus {
    outline: 0;
    border: 0;
    box-shadow: none !important;

    &.disabled {
      opacity: 0.4;
      cursor: default !important;
      pointer-events: none;

      &:hover {
        color: variable.$text-color-white;
      }
    }
  }

  .p-accordion {
    padding-left: 10px;
    padding-right: 10px;
  }

  button.wi-node-button {
    width: 2rem;
    height: 2rem;
    border-radius: 16px;
    border-radius: 50%;
    color: variable.$text-color-white;

    &:nth-child(odd) {
      margin-right: 8px;
    }

    &.wi-node-button-remove {
      background-color: variable.$red-icon;
    }

    &.wi-node-button-edit {
      background-color: variable.$second-green-color;
    }

    &.wi-node-button-edit:hover,
    &.wi-node-button-remove:hover {
      color: variable.$coupon-new;
    }

    &.wi-node-button-create {
      background-color: variable.$coupon-new;
      color: variable.$text-color-white;

      &:hover {
        color: variable.$text-color-white;
        background-color: variable.$second-green-color;
      }
    }
  }

  .wi-tree-header {
    padding: 8px;
    margin: 0;
    display: flex;
    flex-direction: row wrap;
    justify-content: space-between;
    align-items: center;
    background-color: variable.$second-green-color;

    label {
      text-transform: uppercase;
      color: variable.$text-color-white;
      font-size: 12px;
      font-weight: 700;
    }

    .language-mode .lang-item {
      padding: 2px 6px 6px;
      border-radius: 4px;
      cursor: pointer;
      filter: grayscale(1);

      &.mode-active {
        filter: grayscale(0);
      }

      .icon-lang {
        width: 30px;
        border-radius: 3px;
      }

      &:not(.mode-active) {
        filter: grayscale(100%);
      }
    }
  }

  .p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 8px;
    margin: 0;
    background-color: variable.$light-green-row;
    border-radius: 50px;
    box-shadow: none;

    .p-treenode-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: calc(100% - 8rem);

      .p-name {
        width: 50%;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        p {
          margin-bottom: 0;
        }
      }

      .p-url {
        width: 50%;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .btn-container {
      width: 6rem;
      text-align: right;
    }

    &:focus {
      box-shadow: inset 0 0 0 1px variable.$second-green-color;
    }
  }
}
