@use '../../../../utils/variable.scss' as variable;

.form-element {
  .p-inputtext {
    font-size: 13px;
    padding: 0.5rem 0.75rem;
    font-weight: 400;
    color: variable.$default-color-text;
  }
}
