@use '../../../utils/variable.scss' as variable;

.patner-details {
  width: 100%;
  height: 100%;

  sub,
  sup {
    font-size: variable.$text-sup-size !important;
  }

  a {
    color: variable.$primary-link-color;
    &:hover {
      color: variable.$primary-link-hover-color;
    }
  }

  strong,
  strong > span {
    font-weight: 700 !important;
  }
  .partner-details-container {
    .partner-details-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid variable.$background-color-sidebar;
    }

    .partner-details-content {
      .content-container {
        width: 100%;
        height: 100vh;
        overflow: auto;
        padding-bottom: 200px;

        .partner-header {
          position: relative;
        }

        .partner-content {
          text-align: left;

          .partner-text {
            font-family: 'Poppins', sans-serif;
            color: variable.$black;
            font-size: variable.$text-default-size;
            font-weight: 400;

            &.label {
              font-size: variable.$text-h2-size;
              font-weight: 700;
            }
          }
          .partner-content-info {
            .text-des {
              color: variable.$color-time;
            }

            .wi-tag-status {
              width: fit-content;
              margin-left: 0 !important;
            }

            .partner-prifile-page {
              display: flex;
              align-items: center;
            }
          }

          .partner-contact-information {
            .contact-info {
              display: flex;
              width: 100%;
              height: 104px;
              .contact-info-avatar {
                width: 104px;
                height: 104px;
                min-width: 104px;
                min-height: 104px;
                background-color: variable.$background-avatar-partner;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                .avatar {
                  color: variable.$white;
                  font-size: 40px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                }
              }

              .contact-info-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .contact-info-item {
                  display: flex;
                  align-items: center;
                  .icon-item {
                    svg {
                      width: 24px;
                      height: 24px;
                    }
                  }
                  .text-item {
                    font-family: 'Poppins', sans-serif;
                    font-size: variable.$text-default-size;
                    font-weight: 400;
                    color: variable.$black;
                    &.name {
                      font-weight: 700;
                    }

                    &.line {
                      text-decoration: underline;
                      cursor: pointer;
                      a {
                        color: variable.$black;
                      }
                    }
                  }
                }
              }
            }
            .partner-description,
            .partner-facts {
              .content-text {
                font-family: 'Poppins', sans-serif;
                font-size: variable.$text-default-size;
                font-weight: 400;
                color: variable.$black;
                p {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
