.wi-password-v2.p-password .p-inputtext {
  width: 100%;
  height: 48px;
  border-radius: 16px;
  font-size: variable.$text-default-size;
  font-family: 'Poppins', sans-serif;
  box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
  border: none;

  &:focus,
  &:hover {
    border: 0 !important;
    border-color: variable.$primary-color !important;
    box-shadow: 0px 0px 2px 0px variable.$primary-color;
  }

  &.p-disabled {
    background-color: variable.$bg-disabled-color;
    box-shadow: none;

    .p-dropdown-trigger-icon {
      color: variable.$icon-disabled !important;
    }
  }

  &:focus-visible {
    border: 0 !important;
  }

  &:focus-visible {
    border: 0 !important;
  }
}
