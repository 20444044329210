@use '../../../utils/variable.scss' as variable;

.confirm-password-dialog-v2 {
  border-radius: 44px !important;
  overflow: hidden;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    align-items: flex-start !important;
    padding: 24px !important;

    .confirmation-content {
      display: flex;
      gap: 16px;
      flex-direction: column;
      align-items: center !important;
      line-height: 1.3;
      font-size: variable.$text-default-size;
      margin: 0;

      .confirmation-container {
        .error {
          position: relative;
          color: variable.$red-error;
          letter-spacing: 1px;
          text-transform: uppercase;
          line-height: 1.3;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 0px 0px 24px;

          &::before {
            display: block;
            content: url(../../../assets/images/errorIcon.svg);
            position: absolute;
            left: 0px;
            top: 8px;
            width: 18px;
            height: 18px;
          }
        }
      }

      *:not(.pi):not(.fa-solid) {
        font-family: 'Poppins', sans-serif !important;
      }
    }
  }

  .p-dialog-footer {
    display: flex;
    padding: 0px 24px 24px 24px !important;
    justify-content: center;
    gap: 24px;
  }
}
