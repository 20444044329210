@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.create-region-form {
  .create-region-form-container {
    @extend .form-content;

    .tab-error {
      a {
        background-color: rgba(255, 0, 0, 0.1019607843) !important;
      }
    }

    .sticky-header-form {
      margin-bottom: 10px;
    }

    .btn-submit-container {
      .btn-submit {
        background-color: transparent;
        border: 1px solid variable.$white;

        &:hover {
          border: 1px solid variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }

    .row-title {
      h3 {
        margin: 0;
        color: variable.$coupon-new;
        font-size: 25px;
      }
    }

    .row-content {
      .element-form {
        padding: 15px 0px;

        .p-dropdown.p-disabled {
          background-color: #dcdcdc;
          .p-dropdown-trigger-icon {
            color: #dcdcdc;
          }
        }

        .region-logo {
          &.logo-form {
            width: 100% !important;
            height: 126px !important;
            min-height: 126px !important;
            margin-left: 0 !important;

            .image-cert {
              width: 100%;
              height: 124px;
              object-fit: cover;
              object-position: 50% 50%;
              border-radius: 8px;
            }
          }
        }

        .label-upload-field {
          padding: 0 !important;
          &.partner_logo {
            padding: 0;
            margin: 0;
            width: 100% !important;
            height: 126px !important;
            min-height: 126px !important;
            margin-left: 0 !important;

            .image-cert {
              width: 100%;
              height: 124px;
              object-fit: cover;
              object-position: 50% 50%;
              border-radius: 8px;
            }
          }

          &.partner-mobile-picture {
            margin: auto;
          }
        }
        .input-upload {
          display: none;
        }
        label {
          display: block;
          font-size: 12px;
          text-transform: uppercase;
          color: variable.$light-slate-gray;
          padding-bottom: 4px;
          position: relative;
          padding-right: 8px;
          font-style: italic;

          .required-label {
            color: variable.$red-error;
          }
        }
      }
    }
  }
}
