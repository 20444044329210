@use '../../../utils/variable.scss' as variable;

.input-upload {
  display: none;
}

.label-upload-field-v2 {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px dashed variable.$color-time;
  background-color: variable.$white;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .icon-panel {
    position: absolute;
    display: none;
    background: variable.$background-header;
    padding: 4px;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
  }

  &:hover {
    .icon-panel {
      display: flex;
    }
  }

  .media-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .upload-placeholder {
    font-size: variable.$text-min-size;
    color: variable.$color-light-blue;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    display: flex;
    flex-direction: column;

    .upload-placeholder-icon {
      border-radius: 8px;
      padding: 8px;
      display: flex;
      height: variable.$primary-element-height;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      background: variable.$background-header;
      margin-bottom: 14px;
    }
  }
}

.label-upload-field-logo {
  width: 104px;
  height: 104px;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
  // border-radius: 72px 72px 24px 24px;
  border-radius: 52px;
  box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;

  &.square-logo {
    border-radius: 28px;
  }

  .background-edit-avatar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: variable.$background-edit-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: variable.$white;
      }
    }
  }

  .media-preview {
    width: 104px;
    height: 104px;
    padding: 5px;
    object-fit: contain;
    object-position: center;
    background-color: variable.$white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-panel {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}
