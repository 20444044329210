@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.coupon-form {
  .btn-submit-container {
    .btn-submit {
      background-color: transparent;
      border: 1px solid variable.$white;

      &:hover {
        border: 1px solid variable.$second-yellow-color;
        color: variable.$second-green-color;
      }
    }
  }

  .p-buttonset .p-button {
    padding: 10px 15px !important;
  }

  .global-page-item {
    display: flex;

    .global-page-picture {
      height: 70px;
      width: 100px;
      min-width: 100px;
      border-radius: 20px;
      background: variable.$light-slate-gray;

      > img {
        height: 70px;
        width: 100px;
        border-radius: 20px;
        background: variable.$light-slate-gray;
        object-fit: cover;
        object-position: center;
      }
    }

    .page-details {
      padding: 0 10px;

      .global-page-name {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        text-overflow: ellipsis;
        width: 300px;
        white-space: nowrap;
        overflow: hidden !important;
      }

      .global-page-position {
        color: variable.$light-slate-gray;
        font-size: 12px;
        width: 300px;
      }
    }
  }

  .coupon-form-container {
    @extend .form-content;

    .total-area-banner {
      height: 70px;
      background: variable.$second-green-color;
      overflow: hidden;
      position: relative;
      border-radius: 8px;

      > img {
        width: 100%;
        height: 100px;
      }

      &:hover {
        > img,
        > .total-area-title {
          animation: zoom-in-zoom-out 2s;
        }
      }

      .total-area-title {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        text-align: center;
        font-size: 24px;
        color: variable.$text-color-white;
        display: flex;
        flex-direction: column;
      }
    }

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    .row-title {
      h3 {
        margin: 0;
        color: variable.$coupon-new;
        font-size: 25px;
      }
    }

    .btn-submit-code {
      background-color: transparent;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      border-radius: 20px;
      width: fit-content;
      cursor: pointer;

      button {
        background-color: variable.$primary-green-color;
        &:hover {
          background-color: variable.$second-yellow-color;
        }
      }
    }

    .certificate_logo {
      margin: 0;
      padding: 0;

      &.logo-form {
        width: 100% !important;
        height: 132px !important;
        min-height: 132px !important;
        margin-left: 0 !important;

        .image-cert {
          width: 100%;
          height: 130px;
          object-fit: cover;
          object-position: 50% 50%;
          border-radius: 8px;
        }
      }
    }

    .upload-csv-file {
      padding: 0;
      margin: 0;

      .label-upload-csv {
        padding-top: 12px !important;
        text-align: center;
        max-width: 160px;
        width: 160px;
        height: 42px;
        padding: 0.5rem;
        font-weight: 600;
        font-style: normal !important;
        background-color: variable.$second-green-color;
        color: variable.$text-color-white !important;
        border: 1px solid variable.$second-green-color;
        border-radius: 20px;
        font-size: 12px !important;
        transition: 0.5s;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: variable.$text-color-white;
          color: variable.$second-green-color !important;
          border: 1px solid variable.$second-green-color;
        }
      }

      .name-file {
        padding-top: 2px;
        padding-left: 10px;
      }
    }
  }
}

.expire-calendar {
  z-index: 2800 !important;
}
