@use '../../../../../utils/variable.scss' as variable;

.aw_icon {
  font-family: 'wildicon' !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.header-editor-wrapper {
  .header-wrapper {
    width: 100%;
    gap: 32px;
    margin: 0 auto;
    justify-content: center;
    box-shadow: 0 0 0 100vmax variable.$white !important;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, minmax(min(0rem, 100%), 20rem));

    sup {
      bottom: -0.25em;
      font-size: 0.75em;
    }

    sub {
      bottom: -0.25em;
      font-size: 0.75em;
    }

    &.header-wrapper-max-width {
      max-width: 1378px;
      display: flex;
      gap: 64px;
    }

    .mb16 {
      margin-bottom: 16px;
    }

    .pl24 {
      padding-left: 24px;
    }

    .pr24 {
      padding-right: 24px;
    }

    .mr8 {
      margin-right: 8px;
    }

    .ml4 {
      margin-left: 4px;
    }

    .p-container {
      font-family: inherit;

      p {
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        color: inherit;
      }
    }

    .aw_icon {
      font-family: 'wildicon' !important;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }

    .header-sub-item-label .aw_icon {
      font-size: 0.65em;
      vertical-align: baseline;
      display: inline-block;
    }

    .item-card {
      position: relative;
      border-radius: 1rem;
      padding-bottom: 4.5rem;
      overflow: hidden;
      height: 100%;
      background-color: variable.$snow;

      .item-card-image {
        aspect-ratio: 5/3;
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
      }

      .item-card-body {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5em;
        font-family: 'Lato', Arial, sans-serif;
        font-style: normal;
        letter-spacing: 0;

        .hover-no-underline {
          color: #004432;
          display: block;
          line-height: 1rem;
          text-decoration: none;

          .prime {
            font-size: 1.5rem !important;
            letter-spacing: 0;
            margin: 0;
            font-family: 'Sora', Arial, sans-serif;
            font-weight: 700;
            font-style: normal;
            line-height: 1.3em;
            text-align: left;
          }
        }

        .grey-60 {
          color: variable.$light-slate-gray;
        }

        .aw-rich-text {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .item-card-footer {
        position: absolute;
        left: 1.5rem;
        bottom: 2rem;
      }

      .text-button {
        text-align: left;
        appearance: none;
        display: inline-block;
        width: max-content;
        max-width: 100%;
        width: 100%;
        font-family: 'Sora', Arial, sans-serif;
        font-weight: 600;
        line-height: 1em;
        text-decoration: none;
        cursor: pointer;
        font-size: 18px;
        letter-spacing: 0;
        border: 0;
        background: 0 0;
        padding: 0.4rem 0 0.5rem;
        color: variable.$second-green-color;

        &::after {
          font-family: 'wildicon', sans-serif;
          position: relative;
          content: '\e901';
          margin-left: 0.5rem;
          font-size: 0.8125rem;
          display: inline-block;
        }
      }
    }

    .item-card.item-card-image-only {
      background-color: transparent;
      height: auto;
      padding-bottom: 0;

      .item-card-image {
        border-radius: 16px;
      }
    }

    .header-column {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      flex: 1 1 auto;

      &:nth-child(4) {
        max-width: 20rem;
      }
    }

    .header-item {
      position: relative;

      .header-menu {
        position: absolute;
        display: flex;
        flex-direction: row;
        background: white;
        z-index: 100;
        top: -50px;
        left: -2px;
        border: 2px solid variable.$blue-outline;
        border-radius: 10px;
        // height: 50px;

        .header-menu-item {
          margin: 5px;
          padding: 2px;
          border-radius: 8px;
          height: 30px;
          width: 30px;

          &:hover {
            background: variable.$snow;

            i {
              color: variable.$second-green-color;
            }
          }
        }
      }

      .header-item-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .aw_menu_item {
          text-align: left;
          line-height: 16px;

          a:hover,
          a:hover div {
            text-decoration: underline;
          }
        }

        .header-item-label {
          text-align: left;
          font-family: 'Sora', Arial, sans-serif;
          font-weight: 700;
          font-style: normal;
          color: variable.$primary-green-color;
          line-height: 16px;
          font-size: 16px;
          text-decoration: none;
        }

        .header-sub-item-label {
          font-family: 'Lato', Arial, sans-serif;
          color: variable.$primary-green-color;
          display: block;
          line-height: 16px;
          text-decoration: none;
          font-size: 16px;
        }

        &.header-item-wrapper-description {
          gap: 24px;

          .header-item-label .header-item-label.p-container {
            font-family: 'Sora', Arial, sans-serif;
          }

          .header-item-label .header-item-desscription {
            font-family: 'Lato', Arial, sans-serif;
            font-weight: 400;
            font-style: normal;
            margin: 4px 0 0;
            font-size: 14px;
            line-height: 20.8px;
            color: #333;
            text-decoration: none;
          }

          a:hover {
            text-decoration: none;

            span {
              text-decoration: underline;
            }
          }
        }

        &.header-item-wrapper-bold {
        }
      }
    }
  }
}
