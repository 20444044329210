@use '../../../../../utils/variable.scss' as variable;

.header-tab-content {
  padding: 60px 0px;
}

.header-page-wrapper {
  display: flex;
  justify-content: space-between;

  .header-title {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Sora', sans-serif;
    font-weight: 700;
  }

  .header-operation-panel {
    display: flex;
    justify-content: flex-end;

    button.wi-button.secondary {
      padding: 0px 20px;
      min-height: 30px;
      font-size: 14px;
      line-height: 14px;
      font-family: 'Sora', sans-serif;
    }
  }
}

.header-column:hover {
  .btn-add-new {
    visibility: visible;
  }
}

.btn-add-new.p-button {
  background: variable.$light-gray;
  border: 1px dashed variable.$status-cancelled;
  color: variable.$status-cancelled;
  visibility: hidden;

  &:enabled:hover {
    border: 1px dashed variable.$status-cancelled;
    background: variable.$status-cancelled;
    color: variable.$white;
  }
}

.p-dropdown-panel {
  .header-item-label {
    .aw_icon_dropdown {
      width: 30px;
      display: inline-block;
      margin-right: 8px;
    }
  }
}

.header-editor-wrapper {
  .header-column:hover {
    .wi-menu-button-action {
      visibility: visible;
    }
  }

  .wi-menu-button-action {
    visibility: hidden;

    .menu-button {
      width: 100%;
      font-size: 14px;
      background: variable.$light-gray;
      border: 1px dashed variable.$status-cancelled;
      color: variable.$status-cancelled;

      &:enabled:hover {
        border: 1px dashed variable.$status-cancelled;
        background: variable.$status-cancelled;
        color: variable.$white;
      }
    }
  }
}
