@use '../../../utils/variable.scss' as variable;

button.wi-button {
  padding: 0.7rem 1rem;
  min-height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-style: normal;
  font-size: 0.9375rem;
  line-height: 1em;
  letter-spacing: 0;
  border-radius: 4rem !important;
  transition: 0.16s ease-in-out all;

  &.primary {
    background-color: variable.$background-color-white !important;
    color: variable.$second-green-color !important;
    border: 1px solid variable.$second-green-color !important;

    &:hover {
      background-color: variable.$second-green-color !important;
      color: variable.$text-color-white !important;
    }
  }

  &.secondary {
    background-color: variable.$second-yellow-color !important;
    color: variable.$second-green-color !important;
    border: 1px solid variable.$second-yellow-color !important;

    &:hover {
      background-color: variable.$second-green-color !important;
      color: variable.$text-color-white !important;
      border: 1px solid variable.$second-green-color !important;
    }
  }

  &.small {
    min-width: 120px;
    padding: 0.7rem 1rem !important;
    font-size: 0.9375rem !important;

    .pi {
      font-size: 1.25rem;
    }
  }

  &.medium {
    min-width: 160px;
    padding: 0.85rem 1.5rem !important;
    font-size: 1.125rem !important;

    .pi {
      font-size: 1.55rem;
    }
  }

  &.large {
    min-width: 200px;
    padding: 1.17rem 1.5rem !important;
    font-size: 1.5rem !important;

    .pi {
      font-size: 1.9rem;
    }
  }

  a {
    text-decoration: none;
    color: variable.$text-color-white;
  }
}

button.wi-button:disabled,
button.wi-button[disabled] {
  color: variable.$second-green-color !important;
  border: 1px solid variable.$second-green-color !important;
  background-color: variable.$background-color-white-gray !important;
  pointer-events: all !important;
  opacity: 0.8;
  cursor: not-allowed;

  &:hover {
    color: variable.$second-green-color !important;
    border: 1px solid variable.$second-green-color !important;
    background-color: variable.$background-color-white-gray !important;
    pointer-events: all !important;
    cursor: not-allowed;
  }
}
