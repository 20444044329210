@use '../../../../utils/variable.scss' as variable;

.wi-form-dropdown {
  border-radius: 16px;

  &.p-dropdown:not(.p-disabled) {
    &:hover {
      border-color: variable.$background-color-green;
      box-shadow: none;
    }

    &.p-focus {
      border-color: variable.$background-color-green;
      box-shadow: none;
    }
  }
}
