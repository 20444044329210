@use '../../../utils/variable.scss' as variable;
@use '../../../utils/style/style-common.scss';

.copy-right {
  width: 100%;
  position: sticky;
  bottom: 0;
  text-transform: uppercase;
  color: variable.$light-grey-color;
  background: variable.$white;
  font-size: 12px;
  text-align: center;
  z-index: 1;

  .icon-heart {
    color: variable.$red-icon;
  }
}
