@use '../../utils/variable.scss' as variable;

.main-container-v2 .welcome-page {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  text-align: left;

  .date {
    font-size: variable.$text-h5-size;
    color: variable.$color-time;
    font-weight: 500;
    margin: 0;
  }

  .welcome-text {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    margin: 0;
  }

  .normal-text {
    font-size: variable.$text-h5-size;
    margin: 0;
    line-height: 24px;

    a {
      font-size: variable.$text-h5-size;
      font-weight: 700;
      color: variable.$primary-color !important;
      text-decoration: underline !important;

      &:hover {
        color: variable.$secondary-color !important;
      }
    }
  }
}
