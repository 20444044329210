@use '../../../../utils/variable.scss' as variable;

$active-color: variable.$primary-color;
.tree-panel {
  .p-tree {
    padding: 12px;
    width: 100%;
    height: calc(90vh - 245px) !important;
    overflow: auto;
    box-sizing: none !important;
    border: none !important;

    .p-treenode-children {
      padding: 0 0 0 10px;
    }

    .p-treenode-content {
      font-size: 14px;
      padding: 4px 0 !important;
      border-radius: 8px;
      text-align: left;
      word-break: break-all;
      color: variable.$black !important;

      &.p-highlight {
        color: $active-color !important;
        background: rgba($active-color, 0.1) !important;

        .p-tree-toggler .p-tree-toggler-icon {
          color: $active-color !important;
        }
      }

      .p-tree-toggler {
        margin-right: 5px !important;
        width: 25px !important;
        height: 25px !important;
        &:focus {
          box-shadow: none !important;
        }

        &:hover {
          background: variable.$transparent !important;
        }

        .p-tree-toggler-icon {
          color: variable.$black !important;
          min-width: 20px;
          font-size: 12px;
          &::before {
            font-size: 12px;
          }
        }
      }

      .p-treenode-label {
        display: flex;

        span {
          max-width: 200px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon-svg path {
          stroke: $active-color !important;
          fill: $active-color !important;
        }
      }

      .p-treenode-icon {
        color: variable.$primary-color !important;
        display: none;
      }

      &:focus {
        box-shadow: none !important;
      }

      .pi-chevron-right:before {
        font-size: 10px;
      }
      .pi-chevron-right:focus {
        font-size: 10px;
      }
      .pi-chevron-down:before {
        font-size: 10px;
      }
    }
  }

  // .tree-btn {
  //   height: 2rem;
  //   background-color: variable.$primary-color;
  //   font-size: 0.75rem;

  //   &:enabled:hover {
  //     background-color: variable.$primary-color;
  //   }

  //   &:enabled:active {
  //     background-color: variable.$primary-color;
  //   }

  //   &:focus {
  //     box-shadow: 0 0 0 2px variable.$white, 0 0 0 4px variable.$chartreuse-color,
  //       0 1px 2px 0 variable.$black;
  //   }
  // }

  // .tree-panel-header {
  //   display: flex;
  //   align-items: center;
  //   align-content: center;
  //   justify-content: center;
  //   //box-sizing: border-box;
  //   //border: 1px solid #dee2e6;
  //   border-radius: 6px;
  //   height: 2rem;
  //   padding: 5px;
  //   //background-color: rgb(247, 247, 247);
  //   font-size: 14px;

  //   i {
  //     color: variable.$primary-color;
  //     cursor: pointer;

  //     &:hover {
  //       background-color: variable.$secondary-color;
  //     }
  //   }
  // }
}
