@use '../../../../utils/variable.scss' as variable;
@import '../../../../styles/style-common-v2.scss';

.people-form {
  width: 100%;
  height: calc(100vh - 84px - 73px);
  overflow-y: auto;

  .people-form-container {
    width: 552px;
    margin: auto;

    .row-content {
      @extend .row-common;
    }

    .profile_picture_upload {
      .media-preview {
        object-fit: cover !important;
      }
      .label-upload-field-v2 {
        height: 220px !important;
        width: 220px !important;
      }
    }
  }
}
