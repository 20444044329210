@use '../../../../utils/variable.scss' as variable;
@import '../../../../utils/style/style-common.scss';

.import-donor-detail {
  width: 100%;
  flex-direction: column;
  display: flex;
  position: relative;

  .detail-container {
    width: 100%;

    .close-table {
      position: absolute;
      top: 8px;
      right: 8px;
      display: flex;
      cursor: pointer;

      .close-icon {
        padding-top: 2px;
        padding-right: 3px;
        color: variable.$primary-green-color;
      }

      .p-button {
        padding: 0;
        height: 30px !important;
        background: transparent;
        color: variable.$primary-green-color;
        border: none;
        text-decoration: underline;
        .p-button-icon {
          font-size: 10px !important;
        }
      }
    }

    .certificate {
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .table-import {
      margin: 10px 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      // height: calc(100vh - 90px);

      @extend .table-common;
    }

    .btn-send {
      margin-top: 10px;
      width: 100%;
      font-weight: bolder;

      .mail-errors {
        width: 100%;
        text-align: start;
        color: variable.$red-color;
      }

      .status-sendmail {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .sendmail-lable {
          padding-right: 20px;
        }

        button {
          font-weight: 600;
          font-style: normal;
          background-color: variable.$primary-green-color;
          border-radius: 16px;
          color: variable.$text-color-white;
          outline: none;
          border: 0;
          box-shadow: none;
          cursor: pointer;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          font-size: 14px;
        }
      }
    }

    .p-tabview {
      width: 100%;

      .p-tabview-nav-container {
        padding: 0;
        margin: 0;
        width: 100%;

        .p-tabview-nav {
          li {
            a {
              outline: none;
              border: 0;
              box-shadow: none;
              border-radius: 0;
              border-bottom-width: 3px;
            }

            &.p-highlight .p-tabview-nav-link {
              background-color: variable.$second-green-color;
              color: variable.$text-color-white;
              outline: none;
              border: 0;
              box-shadow: none;
            }
          }
        }
      }

      .p-tabview-panels {
        padding: 0;
        width: 100%;

        .p-treetable {
          .p-treetable-wrapper {
            // height: calc(100vh - 205px);
            overflow: auto;
            table {
              thead {
                position: sticky;
                top: 0;
                z-index: 2;
                tr {
                  th {
                    background-color: variable.$second-green-color;
                    color: variable.$text-color-white;
                    font-size: 12px;
                  }
                }
              }
              tbody {
                tr {
                  td {
                    font-size: 12px;
                    button {
                      outline: none;
                      border: 0;
                      box-shadow: none;

                      &:hover {
                        background-color: transparent;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        table {
          &.p-datatable-table {
            .p-datatable-thead tr th {
              background-color: variable.$second-green-color;
              color: variable.$text-color-white;
              display: flex;
              justify-content: flex-start;

              &:hover {
                background-color: variable.$second-green-color;
                color: variable.$text-color-white;
              }

              &.p-sortable-column:focus {
                background-color: variable.$second-green-color;
                color: variable.$text-color-white;
                outline: none;
                border: 0;
                box-shadow: none;
              }

              .p-sortable-column-icon {
                color: variable.$text-color-white;
              }
            }

            .p-datatable-tbody tr td {
              .completed-container,
              .remove-container {
                width: 30px;
                height: 30px;
                border-radius: 20px;
                position: relative;
                margin: auto;

                i {
                  position: absolute;
                  top: 5px;
                  left: 5px;
                  font-size: 20px;
                  font-weight: bolder;
                  color: variable.$text-color-white;
                }
              }

              .completed-container {
                background-color: green;
              }

              .remove-container {
                background-color: variable.$red-color;
              }
            }
          }
        }

        .p-datatable {
          .p-paginator {
            .p-paginator-pages {
              .p-highlight {
                background-color: variable.$light-green-row !important;
                color: variable.$second-green-color !important;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .detail-container .btn-send {
    .mail-errors {
      width: 35%;
    }

    .status-sendmail {
      width: 65%;
    }
  }
}
