@use '../../../../utils/variable.scss' as variable;

.page-form {
  .page-form-container {
    text-align: left;

    .p-dropdown-header {
      border-radius: 0 !important;
      border-left: 1px solid variable.$second-green-color;
      border-right: 1px solid variable.$second-green-color;
      border-bottom: none;
      background-color: transparent;
      padding: 10px;
      padding-top: 20px;
    }

    .url-group {
      .p-inputgroup-addon {
        height: 40px;
        font-size: 14px;

        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }

      input {
        border-radius: 0 !important;
        // border-top-left-radius: 0!important;
        // border-bottom-left-radius: 0!important;
        flex: 1;
      }

      &.url-group-draft {
        a.p-inputgroup-addon {
          pointer-events: none;
        }
      }

      a.p-inputgroup-addon {
        text-decoration: none;

        &:hover {
          color: variable.$second-green-color !important;
        }
      }
    }

    .url-note {
      font-size: 13px;
      color: variable.$red-error;
      margin-top: 5px;
    }

    .page-item {
      font-size: 13px;
      font-weight: 400;
    }

    .p-accordion .p-accordion-header-link,
    .p-accordion .p-highlight .p-accordion-header-link {
      background: variable.$white-green !important;
      border: variable.$white-green !important;
      outline: none !important;
      box-shadow: none !important;
      color: variable.$second-green-color !important;
      padding: 15px 20px;
    }

    .p-accordion .p-accordion-content {
      height: auto;
      overflow: auto;
      padding: 5px 10px;
      overflow-x: hidden;
    }

    .row-content {
      .col-12 {
        padding: 0px 15px 5px 15px;

        &.col-md-6 {
          &:first-child {
            padding-right: 5px;
          }
          &:last-child {
            padding-left: 5px;
          }
        }
      }

      .element-form {
        padding: 5px 0px;

        .search-checkbox {
          max-width: 200px !important;
        }

        .input-upload {
          display: none;
        }

        .p-selectbutton {
          width: 100%;

          .p-button {
            padding: 12px 10px !important;
            width: 50%;
          }
        }

        .p-selectbutton .p-button .p-button-label {
          font-size: 13px;
        }

        .label-upload-field {
          margin: 0;
          width: 80%;
          padding-top: 28.25%;
          padding-right: 5px;
          padding-left: 5px;
          padding-bottom: 18.25%;

          img {
            position: absolute;
            top: 0;
            height: 100%;
            max-height: 100%;
            object-fit: fill;
            max-width: 98%;
          }

          .remove-icon {
            z-index: 99;
          }
        }

        label {
          display: block;
          font-size: 12px;
          text-transform: uppercase;
          color: variable.$light-slate-gray;
          padding-bottom: 4px;
          position: relative;
          padding-right: 8px;
          font-style: italic;

          .label-unit {
            text-transform: lowercase;
          }
          .required-label {
            color: variable.$red-error;
          }
        }
        .p-dropdown {
          width: 100%;
          height: 40px;
          border-radius: 16px;
          position: relative;
          .p-dropdown-label {
            display: flex;
            align-items: center;
            color: variable.$second-green-color;
            font-size: 14px;
          }

          &:focus-visible {
            box-shadow: none;
            outline: none;
            border: 1px solid variable.$second-green-color !important;
          }

          .p-dropdown-trigger {
            transition: 0.16s ease-in-out all;
            .p-dropdown-trigger-icon {
              transform: rotate(0deg);
              transition: 0.16s ease-in-out all;
              color: variable.$second-green-color;
            }
          }

          .p-dropdown-panel {
            border-radius: 0 0 16px 16px;
            box-shadow: none !important;
            transform-origin: center top !important;
            top: 26px !important;
            .p-dropdown-items-wrapper {
              border-left: 1px solid variable.$second-green-color;
              border-bottom: 1px solid variable.$second-green-color;
              border-right: 1px solid variable.$second-green-color;
              border-radius: 0 0 16px 16px;
              overflow-y: auto;
              max-height: 210px;

              .p-dropdown-item {
                font-weight: 600;
                padding: 5px 16px;
                font-size: 16px;
                color: variable.$second-green-color;
                text-transform: none;
                cursor: pointer;
              }
              .p-dropdown-item.p-highlight {
                background: variable.$background-color-white-gray;
                color: variable.$primary-green-color !important;
              }
            }
          }
        }

        .p-dropdown:not(.p-disabled):hover,
        .p-dropdown:not(.p-disabled).p-focus {
          box-shadow: none;
          outline: none;
          border: 1px solid variable.$second-green-color !important;
        }

        .p-dropdown:not(.p-disabled).p-focus {
          .p-dropdown-trigger {
            transition: 0.16s ease-in-out all;
            .p-dropdown-trigger-icon {
              transform: rotate(180deg);
              transition: 0.16s ease-in-out all;
            }
          }
        }

        .p-dropdown-panel {
          left: -1px !important;
          width: calc(100% + 2px) !important;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type='number'] {
          -moz-appearance: textfield;
        }

        .p-inputnumber,
        .p-calendar {
          width: 100%;
        }

        input {
          border: 1px solid variable.$background-color-white-gray;
          color: variable.$black-gray-color;
          border-radius: 16px;
          height: 40px;
          padding: 8px 16px;
          width: 100%;
          font-size: 14px;
          background: variable.$background-color-white;
          text-align: left;
          position: relative;
          &:focus-visible {
            outline: none;
            border: 1px solid variable.$second-green-color !important;
          }
        }

        textarea {
          border: 1px solid variable.$background-color-white-gray;
          color: variable.$black-gray-color;
          border-radius: 16px;
          height: 24px;
          padding: 8px 16px;
          width: 100%;
          background: variable.$background-color-white;
          text-align: left;
          position: relative;
          min-height: 130px;
          font-size: 14px;
          &:focus-visible {
            outline: none;
            border: 1px solid variable.$second-green-color !important;
          }
        }

        .has-error {
          border: 1px solid variable.$red-error;
          border-radius: 16px;
        }

        .field-disabled-url-true {
          background-color: variable.$pattent-blue;
        }

        .dropdown-menu {
          border: 1px solid variable.$second-green-color;
          width: 100%;
          border-radius: 16px;
          margin: 10px 0px;
        }
        .error {
          position: relative;
          color: variable.$red-error;
          letter-spacing: 1px;
          text-transform: uppercase;
          line-height: 1.3;
          font-size: 12px;
          font-weight: 400;
          padding: 10px 0px 0px 24px;

          &::before {
            display: block;
            content: url(../../../../assets/images/errorIcon.svg);
            position: absolute;
            left: 0px;
            top: 8px;
            width: 18px;
            height: 18px;
          }
        }

        .field-checkbox,
        .field-radiobutton {
          margin-bottom: 1rem;
          display: flex;
          align-items: center;

          .p-checkbox {
            display: inline-flex;
            cursor: pointer;
            user-select: none;
            vertical-align: bottom;
            position: relative;
          }
          label,
          .field-radiobutton > label {
            margin-left: 0.5rem;
            line-height: 1;
          }
          .p-checkbox .p-checkbox-box.p-highlight {
            border-color: variable.$second-green-color;
            background: variable.$second-green-color;
          }
          .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
            border-color: variable.$second-green-color;
          }
        }
        .donation-checkbox.checkbox-container {
          input {
            display: none;
          }
        }

        .donation-checkbox.checkbox-container input[type='checkbox'] + label {
          svg {
            width: 26px;
          }

          span {
            font-size: 15px;
          }
        }

        .multiselect-custom {
          width: 100%;
          height: 48px;
          border-radius: 16px;
          position: relative;

          .country-item {
            margin-left: 10px;
          }

          .p-multiselect-label {
            display: flex;
          }
        }
      }
    }

    .btn-submit-container {
      width: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      position: absolute;
      bottom: -60px;
      right: 0;
      justify-content: flex-end;
      //background-color: #FFFFFF;

      button {
        padding: 1rem 1.5rem;
        margin: 1rem 1.5rem;
        background-color: variable.$primary-green-color;
      }
    }

    #btn-submit-page {
      &.p-button {
        border-color: variable.$second-green-color;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background-color: variable.$second-yellow-color;
          border-color: variable.$second-yellow-color;
          color: variable.$second-green-color;
        }
      }
    }
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: variable.$primary-green-color;
}

.country-item {
  display: flex;
  align-items: center;

  .flag {
    margin-right: 4px;
  }

  .language {
    margin-left: 10px;
  }
}
