@use '../../../utils/variable.scss' as variable;
.row-content {
  .a4 {
    background: variable.$background-color-white;
    display: block;
    margin: 0 auto;
    padding: 0.4in;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm variable.$black-opac-half;
    width: 21cm;
    height: 29.7cm;

    .pdf-certificate {
      margin: 0;
      padding: 0;
      position: 'relative';

      .pdf-header {
        width: 100%;
        height: 265px;

        .pdf-header-section-left {
          margin: 0;
          width: 25%;
          float: left;
        }

        .pdf-header-section-right {
          margin-right: -10px;
          width: 58%;
          float: right;
          position: relative;
          height: 265px;
        }
      }

      .pdf-body {
        margin-top: 3rem;
        width: 100%;
        position: relative;

        .pdf-body-section-left {
          margin: 0;
          width: 55%;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;

          .pdf-title {
            margin: 0;
            width: 100%;
            color: variable.$primary-green-color;
            font-family: 'Sora';
            font-size: 38px;
            letter-spacing: 1.2px;
            line-height: 1.4;
            font-weight: 700;
            text-align: center;
          }

          .pdf-title-second {
            margin: 0 0 30px 0;
            width: 100%;
            color: variable.$primary-green-color;
            font-family: 'Sora';
            font-size: 38px;
            letter-spacing: 1.2px;
            line-height: 1.4;
            font-weight: 300;
            color: variable.$primary-green-color;
            text-align: center;
          }

          .pdf-donor {
            margin: 0;
            height: 210px;

            .pdf-donor-title {
              width: 100%;
              color: #004332;
              font-family: 'Sora';
              font-size: 16px;
              font-weight: 700;
              text-align: center;
            }

            .pdf-donor-fullname {
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: 'Sora';
              font-size: 28px;
              font-weight: 700;
              letter-spacing: 0px;
              text-align: center;
              word-wrap: break-word;
              margin: 0;
              padding-bottom: 10px;
              line-height: 1.4;
            }

            .pdf-donor-reason {
              width: 100%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 0px;
              text-align: center;
              word-wrap: break-word;
              margin: 0;
              padding-bottom: 10px;
              line-height: 1.4;
            }
          }

          .pdf-donor-area {
            margin: 0;
            min-height: 142px;

            .pdf-area-amount {
              margin: 0 0 5.92px 0;
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: 'Sora';
              font-size: 28px;
              line-height: 1.5;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;

              sup {
                vertical-align: super;
                font-size: 16px;
              }
            }

            .pdf-area-title {
              margin: 0 0 5.92px 0;
              width: 100%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 16px;
              line-height: 1.53;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;
            }

            .pdf-area-title-second {
              margin: 0 auto 32.87px auto;
              width: 80%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 16px;
              line-height: 1.5;
              font-weight: 300;
              letter-spacing: 0px;
              text-align: center;
            }
          }

          .pdf-co2 {
            margin: 0;
            min-height: 132px;
            margin-top: 20px;

            .pdf-co2-amount {
              margin: 0 0 5.93px 0;
              width: 100%;
              color: variable.$chartreuse-color;
              font-family: 'Sora';
              font-size: 28px;
              line-height: 1.5;
              font-weight: 700;
              letter-spacing: 0;
              text-align: center;

              sup {
                vertical-align: sub;
                font-size: 14px;
              }
            }

            .pdf-co2-title {
              margin: 0 auto 0 auto;
              width: 70%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 16px;
              line-height: 1.5;
              font-weight: 300;
              letter-spacing: 0;
              text-align: center;
            }
          }
        }

        .pdf-body-section-right {
          width: 50%;
          position: relative;
          top: 0%;
          left: 56%;
          text-align: left;

          .pdf-logo {
            margin: 0;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
          }

          .pdf-name {
            margin: 0 auto 30.52px auto;
            width: 100%;
            color: variable.$default-color-text;
            font-family: 'Sora';
            font-size: 11px;
            line-height: 1.2;
            font-weight: 300;
            letter-spacing: 0;
            text-align: center;
          }

          .pdf-info {
            width: 100%;
            margin: 0;

            .geo-coordinate {
              margin: 0rem auto;
              width: 80%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 28px;
              line-height: 100%;
              font-weight: 700;
              text-align: center;
              line-height: 1.3;
            }

            .certificate-description {
              margin: 0 auto;
              width: 74%;
              color: variable.$default-color-text;
              font-family: 'Sora';
              font-size: 11px;
              line-height: 1.7;
              font-weight: 300;
              letter-spacing: 0;
              text-align: left;
            }

            .qr-section {
              margin: 0.4rem auto;
              width: 74%;
              text-align: left;

              .qr-description {
                width: 142pt;
                float: left;

                p {
                  margin: 0 auto;
                  width: 100%;
                  color: variable.$default-color-text;
                  font-family: 'Sora';
                  font-size: 9px;
                  line-height: 1.5;
                  font-weight: 300;
                  letter-spacing: 0;
                  text-align: left;
                }
              }

              .qr-code-image {
                width: 50pt;
                float: right;
                margin: 0;
              }
            }

            .donation-section {
              margin: 3rem auto 0 auto;
              width: 74%;
              text-align: left;

              .donation-section-info {
                width: 100%;
                float: left;

                .donation-serial {
                  margin: 0 auto 4.6px auto;
                  width: 100%;
                  color: variable.$default-color-text;
                  font-family: 'Sora';
                  font-size: 14px;
                  line-height: 1.5;
                  font-weight: 300;
                  letter-spacing: 0;
                  text-align: left;
                }

                .donation-date {
                  margin: 0 auto 0 auto;
                  width: 100%;
                  color: variable.$default-color-text;
                  font-family: 'Sora';
                  font-size: 12px;
                  line-height: 1.5;
                  font-weight: 300;
                  letter-spacing: 0;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      .pdf-footer {
        position: absolute;
        width: 100%;
        height: 115px;
        left: 0px;
        top: 0px;
        bottom: 0;
        margin-top: calc(100% - 75px);

        .pdf-footer-logo {
          margin: 0;
          height: 140px;
          position: absolute;
          bottom: 5px;
          left: 0;
          img {
            margin: 35px 0 auto 0;
            text-align: left;
            width: 104px;
          }
        }

        .pdf-footer-signature {
          margin: 0;
          position: absolute;
          bottom: 5px;
          left: 139px;
          img {
            margin: 0 auto;
            width: 104px;
            color: variable.$default-color-text;
          }
        }
        .sign-person-name {
          width: 100%;
          color: variable.$default-color-text;
          font-size: 10px;
          font-family: variable.$font-name-Sora;
          letter-spacing: 0.56px;
          line-height: 1.2;
          font-weight: 700;
          font-style: normal;
          text-align: left;
        }
        .sign-person-detail {
          margin: 0;
          width: 115px;
          color: variable.$default-color-text;
          font-size: 9px;
          font-family: variable.$font-name-Sora;
          letter-spacing: 0;
          line-height: 1.2;
          font-weight: 300;
          font-style: normal;
          text-align: left;
        }
      }
    }
  }
}
