.wi-checkbox-v2 {
  display: flex;

  input[type='checkbox']:checked + .checkbox-label-wrapper {
    svg {
      .box {
        stroke: variable.$primary-color !important;
      }

      .box-checked {
        fill: variable.$primary-color;
        stroke: variable.$primary-color !important;
      }
    }
  }

  .checkbox-label-wrapper {
    display: flex !important;
    padding: 0 !important;
    align-items: center;

    svg {
      width: 20px !important;
      position: static !important;

      .check {
        stroke: variable.$white !important;
      }

      .box {
        stroke: rgba(variable.$black, 0.25) !important;
      }

      .box-checked {
        fill: variable.$transparent;
        stroke: variable.$transparent !important;
      }
    }

    .checkbox-label {
      font-family: 'Poppins', sans-serif;
      color: variable.$light-slate-gray;
      font-size: variable.$text-default-size;
      font-style: italic;
    }
  }
}
