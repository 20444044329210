@use '../../utils/variable.scss' as variable;
@import '../../utils/style/style-common.scss';

.form-mode {
  width: 100%;

  input:disabled,
  textarea:disabled {
    background: variable.$background-color-white-smoke !important;
  }

  .p-accordion-header-link:focus {
    box-shadow: none !important;
  }

  .p-accordion {
    .p-accordion-toggle-icon {
      font-size: 13px;
    }

    .p-accordion-header {
      .p-accordion-header-link {
        padding: 8px 15px;
        font-size: 13px;
        background-color: variable.$light-green-row;
        border-radius: 18px;
        border: 1px solid variable.$light-green-row;
      }

      &.p-highlight {
        .p-accordion-header-link {
          background-color: variable.$light-green-row !important;
          border-top-left-radius: 18px !important;
          border-top-right-radius: 18px !important;
          border: 1px solid variable.$light-green-row !important;
          // border-color: #13735f!important;
        }
      }
    }

    .p-accordion-content {
      //   padding: 15px;
      padding: 5px 0px;
    }
  }

  &.form-mode-dialog {
    .p-accordion {
      .p-accordion-toggle-icon {
        font-size: 13px;
      }

      .p-accordion-header-link {
        padding: 8px 15px;
        font-size: 13px;
        background-color: variable.$light-green-row;
      }

      .p-accordion-content {
        padding: 15px;
      }
    }

    .apply-btn {
      display: none;
    }

    .footer-panel {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      @extend .button-common;
    }
  }

  &.form-mode-sidebar {
    .row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 12px;

      .col-md-6 {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .col-md-6 {
      width: 100%;
      min-width: 350px;
      flex: 1;
    }

    .footer-panel {
      display: none;
    }
  }

  &:not(.form-mode-sidebar) {
    .form-element-wrapper {
      &:not(:first-child) {
        padding-left: 0;
      }

      &:not(:last-child) {
        padding-right: 0;
      }
    }
  }

  form {
    font-size: 18px;
    width: 100%;
    padding-top: 20px;

    .form-row > .form-row {
      padding: 0;
      margin: 0;
    }

    .item-icon {
      padding: 5px 6px;
      background: variable.$red-icon;
      color: variable.$text-color-white;
      border-radius: 50%;
      margin: auto;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .form-element {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    padding: 10px;
    font-size: 12px;

    // Multilang
    .p-inputgroup-addon,
    .flag-de {
      display: none;
    }

    textarea {
      border: 1px solid variable.$background-color-white-gray;
      color: variable.$black-gray-color;
      border-radius: 16px;
      height: 48px;
      padding: 8px 16px;
      width: 100%;
      background: variable.$background-color-white;
      text-align: left;
      position: relative;
      min-height: 144px;
      &:focus-visible {
        outline: none;
        border: 1px solid variable.$second-green-color !important;
      }
    }

    input {
      border: 1px solid variable.$background-color-white-gray;
      color: variable.$black-gray-color;
      border-radius: 16px;
      height: 40px;
      padding: 8px 16px;
      width: 100%;
      background: variable.$background-color-white;
      text-align: left;
      position: relative;
      &:focus-visible {
        outline: none;
        border: 1px solid variable.$second-green-color !important;
      }
    }

    .has-error {
      border: 1px solid variable.$red-error;
      border-radius: 16px;
    }

    .error {
      position: relative;
      color: variable.$red-error;
      letter-spacing: 1px;
      text-transform: uppercase;
      line-height: 1.3;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 0px 0px 24px;

      &::before {
        display: block;
        content: url(../../assets/images/errorIcon.svg);
        position: absolute;
        left: 0px;
        top: 8px;
        width: 18px;
        height: 18px;
      }
    }

    .form-control {
      font-size: 13px;
    }

    .form-element-title {
      margin-bottom: 5px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .form-item-accordion-tab {
      display: flex;
      width: 100%;

      .form-item-header {
        flex: 1;
        max-height: 40px;
        overflow: hidden;
        margin: auto;
        border: p {
          margin: 0;
        }
      }
    }
  }
}

.p-dialog .p-dialog-content {
  padding-bottom: 20px !important;
}
