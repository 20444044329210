$black: #000000;
$transparent: transparent;
$black-opac-half: #00000080;
$white: #ffffff;
$light-grey-color: #a5a5a5;
$slate-gray: #495057;
$light-slate-gray: #737373;
$black-gray-color: rgb(51, 51, 51);
$blue-sea: rgb(71, 126, 192);
$lavender-color: #6c757d;
$red-color: rgb(255, 0, 0);
$dark-red: #ff00001a;
$dark-green: #13735f33;
$gray-color: #808080;
$pale-green: #83eacb;
$chartreuse-color: #46b17a;
$pattent-blue: #dee2e6;
$gainsboro: #dcdcdc;
$seaGreen: #47c08880;
$dark-blue: #477ec026;
$dark-sea-green: #47c08826;
$mediumSeaGreen: #14735f;
$dimGray: #a9a9a92e;
$snow: #f7f7f7;
$dark-snow: #f7f7f700;
$light-pink: #ff8080;
$white-green: #d4e3df;
$sub-active-color: #94c24a;
$sub-pending-color: #ab8acbc1;
$sub-cancelled-color: #ababab;
$sub-finished-color: #861135;
$default-color-text: #000000;
$text-color-gray: #5f5f5f;
$text-color-white: #ffffff;

$coupon-new: #a6a6a6;
$coupon-expired: #ffd201;
$coupon-active: #4582f5;
$coupon-blocked: #fe7e3d;
$coupon-deleted: #ff5370;
$coupon-used: #3fcd1c;
$red-error: rgb(229, 35, 34);
$red-icon: #ef4444;

$light-green-row: #e0f1ec;
$light-green-color: #00875f;
$primary-green-color: #004432;
$second-yellow-color: #e6ce00;
$second-green-color: #13735f;

$background-color-green: #13735f80;
$background-color-white: #ffffff;
$background-color-white-smoke: #f3f3f3;
$background-color-white-gray: rgb(220, 220, 220);
$background-color-light-steel-blue: #d0d3d4;
$font-name-Sora: Sora;

$label-color: #a9a9a96b;
$value-color: #545969;
$color-darkgray: darkgray;
$color-error: rgb(229, 35, 34);
$label-color-donation: rgb(105 116 134);
$description-color: #687385;

$status-succeeded: #94c24a;
$status-pending: #8acbc1;
$status-failed: #e52322;
$status-refunded: #333333;
$status-cancelled: #ababab;
$status-reserved: #477ec0;

$primary-gray: #ebeef1;
$blur-color: #a9a9a94f;
$blur-gray-color: #a9a9a921;
$blur-shadow-color: #888888;
$staus-not-yet: #f59e0bb3;

// Coupon variables
$coupon-status-active: #8acbc1;
$coupon-status-finished: #861135;
$coupon-progressbar-determinate-background: #959595;
$coupon-progressbar-animation-background: #004432;
$coupon-3-dot-description-color: #778191;

// Layout styles
$bg-user-info: #9b870c;
$bg-search-input: #4f4f4f;
$bg-hover-menuitem: #92d0bd;
$color-text-shadow: rgba(0, 0, 0, 0.5);

$font-default: 13px;

$light-gray: #f1f1f1;
$blue-outline: #0d99ff;
$dark-gray: #4d4d4d;
$main-blue: #004332;
$cancel-color: #dcdcdc;
$white-seven: #ffffff94;

// V2 Refactor
$text-min-size: 12px;
$text-default-size: 14px;
$text-h5-size: 16px;
$text-h4-size: 18px;
$text-h3-size: 20px;
$text-h2-size: 24px;
$text-h1-size: 26px;
$text-icon-size: 16px;
$text-mini-size: 12px;
$text-icon-size: 16px;
$text-mini-size: 12px;
$text-sup-size: 10px;

$text-default-color: #000000;
$text-white-color: #ffffff;
$text-secondary-color: #697486;
$text-highlight-color: #004432;

// ===== NEW UI V2 ===================================
$color-shadow: rgba($black, 0.25);
$background-edit-avatar: rgba($black, 0.4);
$background-disabled: #f7f7f7;
$background-color-sidebar: #e5e5ea;
$background-header: #f3f2f8;
$icon-disabled: #aeaeb2;
$color-disabled: #d1d1d6;
$color-time: #8e8e93;
// One time color
$color-hover: #eceef1; // Row hover
$color-toggle-dis: #cccccc;
$background-backdrop: rgba(171, 171, 171, 0.502);

$color-light-cyan: #00c7be;
$color-teal: #30b0c7;
$color-light-green: #34c759;
$color-light-blue: #007aff;
$color-gray: #3a3a3c;
$color-orange: #ff9500;
$color-light-red: #ff3b30;
$color-hover-red: #950800;
$background-avatar: #32ade6;
$background-avatar-partner: #5856d6;

// =========== V2 THEME VARIABLES ===========================================
// Please use the variables below as much as possible because they are used by the V2 theme system.
// Make sure the new color is consistent with the current colors.
// =========== V2 THEME VARIABLES ===========================================
// Change primary-color, secondary-color if you wan to change theme
$primary-color: $second-green-color;
$secondary-color: $color-light-cyan;
$danger-color: $color-light-red;

$background-toggle-dis: rgba($primary-color, 0.3);

$primary-btn-text-color: $white;
$primary-bg-color: $primary-color;
$primary-btn-text-hover-color: $white;
$primary-bg-hover-color: $secondary-color;

$secondary-btn-text-color: $primary-color;
$secondary-bg-color: rgba($primary-color, 0.1);
$secondary-btn-text-hover-color: $secondary-color;
$secondary-bg-hover-color: rgba($secondary-color, 0.1);

$danger-btn-text-color: $danger-color;
$danger-bg-color: rgba($danger-color, 0.1);
$danger-btn-text-hover-color: $color-hover-red;
$danger-bg-hover-color: rgba($color-hover-red, 0.1);
$box-shadow-color: $color-shadow;
$box-shadow-hover-color: $primary-color;
$bg-disabled-color: $background-disabled;

$primary-link-color: $primary-color;
$primary-link-hover-color: $secondary-color;

$primary-element-height: 40px;
$primary-box-shadow: 0 2px 12px 0 rgba($black, 0.1);
// =========== V2 THEME VARIABLES ===========================================
// Please use the variables below as much as possible because they are used by the V2 theme system.
// Make sure the new color is consistent with the current colors.
// =========== V2 THEME VARIABLES ===========================================
