@use '../../../../../../utils/variable.scss' as variable;
@import '../../../../../../styles/style-common-v2.scss';

.add-form-donation {
  @extend .row-common;

  .add-form-container {
    .row-content {
      .p-search {
        position: relative;
        .p-inputgroup-addon {
          position: absolute;
          left: 0;
          z-index: 2;
          border-top-right-radius: unset !important;
          border-bottom-right-radius: unset !important;
          border-right: unset;
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
          height: 50px;
          background-color: transparent;
          border: none;
        }
        input {
          padding-left: 3rem;
          &:focus {
            border: 1px solid variable.$background-color-white-gray !important;
            box-shadow: 0px 0px 2px 0px variable.$box-shadow-color !important;
          }
        }
      }

      .calendar-item {
        position: relative;
        .p-calendar {
          width: 100%;

          input {
            border-radius: 1rem;
            color: variable.$primary-green-color;
            z-index: 2;
            &:focus {
              border-right: unset !important;
            }
          }
        }
      }

      .check-box-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        label {
          color: variable.$light-slate-gray;
          font-size: 14px;

          svg {
            width: 20px !important;
          }
        }
      }
    }
  }
}

.p-datepicker {
  .p-datepicker-group-container {
    .p-datepicker-calendar,
    .p-yearpicker {
      .p-highlight {
        background-color: variable.$light-green-row !important;
        color: variable.$primary-green-color !important;
      }
    }
  }
}
