

.global-settings {
    .p-datatable-tbody {
        td {
            // overflow: hidden;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
    }
}