@use '../../../utils/variable.scss' as variable;

.wi-toggle-edit-partner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  background: variable.$background-header;
  height: 64px !important;
  .label-toggle {
    font-size: variable.$text-default-size;
    font-weight: 700 !important;
    color: variable.$black;
    font-family: 'Poppins', sans-serif;
  }
}
