@use '../../../../utils/variable.scss' as variable;

.validate-rule {
  path {
    stroke: variable.$black;
  }

  &.validation-pass {
    color: variable.$color-light-green;

    path {
      stroke: variable.$color-light-green;
    }
  }

  .no-space {
    position: relative;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
  }
}
