.editor-photo-dialog {
  .p-dialog-title {
    font-family: 'Poppins', sans-serif !important;
  }

  .tui-image-editor-container {
    .tui-image-editor-header-logo {
      display: none;
    }
    .tui-image-editor-header-buttons,
    .tui-image-editor-controls .tui-image-editor-controls-buttons {
      display: none;
    }
    .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.apply label,
    .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.cancel label {
      vertical-align: 1px;
    }

    .tui-image-editor-help-menu.top {
      height: 45px;
    }

    img,
    svg {
      vertical-align: top;
    }
  }
}
