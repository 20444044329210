@use '../../utils/variable.scss' as variable;
@import '../../utils/style/style-common.scss';

.yearly-manager {
  flex-direction: column;
  display: flex;
  position: relative;

  .p-paginator .p-paginator-pages .p-paginator-page {
    &.p-highlight {
      background-color: variable.$light-green-row;
      color: variable.$second-green-color;
    }
  }

  .wi-tag {
    @extend .wi-tag-custome;

    &.tag-status-active {
      background: variable.$coupon-status-active;
    }

    &.tag-status-finished {
      background: variable.$coupon-status-finished;
    }
  }

  .manager-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .search-box {
      @extend.search-box-common;
    }

    .headline {
      @extend .headline-common;
    }

    .header-containers {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .btn-yearly-panel {
        display: flex;
        button {
          width: 110px;
          font-size: 14px;
          font-weight: 700;
          padding: 0 10px;
          height: 29px;
          align-self: center;
          border-radius: 20px;
          color: variable.$second-green-color;
          background-color: variable.$white;
          border: 1px solid variable.$second-green-color;
          outline: none;
          box-shadow: none;
          margin-left: 10px;

          .p-button-label {
            font-weight: 700;
            font-family: variable.$font-name-Sora;
          }

          .p-button-icon {
            font-size: 14px;
            font-weight: 600;
          }

          &:focus,
          &:hover {
            box-shadow: none;
          }

          &:hover {
            background: variable.$primary-green-color;
            color: variable.$white;
          }
        }
      }

      .generate-button {
        display: flex;
        flex-direction: row;
      }

      .yearly-header-panel {
        width: calc(100% - 120px);
        display: flex;
        flex-direction: row;
      }

      .wi-search-container {
        width: 192px;
        display: inline-block;

        .pi-search {
          font-size: 15px;
          // margin-top: 2px;
        }

        .p-input-icon-left {
          .p-inputtext {
            height: 30px !important;
            padding-top: 0px;
            padding-bottom: 0px;
            font-size: 13px !important;
          }
        }
      }
    }

    .table-yearly-report {
      padding: 0;
      margin: 10px 0 0 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      // height: calc(100vh - 100px);

      @extend .table-common;
      @extend .table-common-extra;

      .p-datatable .p-datatable-wrapper {
        // height: calc(100vh - 140px) !important;
        // max-height: calc(100vh - 140px) !important;

        .p-selection-column {
          .p-checkbox .p-checkbox-box:not(.p-highlight) {
            border-color: variable.$color-darkgray;
          }
        }
      }

      .p-datatable .p-datatable-wrapper .p-datatable-table tr {
        background-color: variable.$white !important;
        border-bottom: none !important;

        td {
          border-bottom: #ebeef1 1px solid;
          &:last-child {
            background-color: variable.$white !important;
          }
        }
      }

      .p-datatable .p-datatable-wrapper .p-datatable-table tr:hover {
        td {
          background-color: variable.$primary-gray !important;
        }
      }

      .btn-serial {
        color: variable.$black;

        &:hover {
          color: variable.$light-green-color;
          font-weight: 700;
        }
      }

      .p-dropdown {
        &:hover,
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: variable.$second-green-color;
        }
      }
    }
  }
}
