@use '../../../../utils/variable.scss' as variable;

.wi-form-toggle-button.p-inputswitch {
  &.p-inputswitch-checked,
  &.p-inputswitch-checked:hover {
    .p-inputswitch-slider {
      background: variable.$second-green-color;
    }
  }
}
