@use '../../../utils/variable.scss' as variable;

.wi-loading-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  background-color: variable.$background-backdrop;

  .wi-loading-backdrop-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .wi-loading-v2 {
    border-radius: 30px;
    // box-shadow: 0px 0px 2px 0px variable.$box-shadow-color;
    background-color: variable.$white;
    position: absolute;
    left: 40%;
    bottom: 10%;

    &.wi-loading-show {
      animation: scale-show 0.3s;
      animation-fill-mode: forwards;
      transform-origin: center;
    }

    .loading-content {
      width: 100%;
      text-align: center;
      padding: 24px;

      .progress-bar {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .p-progress-spinner.progress-spinner-v2 {
        background-image: unset !important;
        .p-progress-spinner-svg {
          .p-progress-spinner-circle {
            stroke: variable.$primary-color !important;
          }
        }
      }
      .p-progressbar .p-progressbar-value-animate {
        background-color: variable.$light-slate-gray !important;
      }

      .message-loading {
        font-weight: 700;
        font-size: variable.$text-default-size;
        font-family: 'Poppins', sans-serif;
        color: variable.$black;
      }
    }
  }
}

.wi-loading-none {
  animation: scale-hide 0.3s;
  animation-fill-mode: forwards;
  transform-origin: center center;
  display: inline-block;
}

.wi-loading-backdrop-none {
  animation: scale-backdrop 0.3s;
  animation-fill-mode: forwards;
  transform-origin: center center;
  display: inline-block;
}

@keyframes scale-backdrop {
  0% {
    opacity: 1;
    display: inline-block;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes scale-show {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes scale-hide {
  0% {
    display: inline-block;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: center center;
  }
  50% {
    display: inline-block;
    opacity: 0.5;
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    transform-origin: center center;
  }
  100% {
    display: none !important;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    transform-origin: center center;
  }
}
