@use '../../../utils/variable.scss' as variable;

.wi-search-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    font-weight: 700;
  }

  input {
    width: 100%;
    height: 48px;
    color: variable.$default-color-text;
    background-color: variable.$light-green-row;
    border-radius: 100px;
    border: 0;

    &:hover,
    &:focus {
      box-shadow: none !important;
    }
  }
}
