@use '../../../../utils/variable.scss' as variable;

.country-label {
  color: variable.$text-color-white;
  padding: 8px 10px;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 5px;
  margin-right: 10px;
  font-style: italic;
  // height: 34px;
  line-height: 1;
  align-self: center;
  border-radius: 20px;
}

.wi-example-tabview {
  .p-tabview {
    .p-tabview-nav-link {
      background: white !important;
      border-bottom-width: 2px !important;
    }
  }

  .p-tabview-panel {
    .card {
      border: none;
      padding: 0 15px !important;
    }
  }
}

.wi-example-table.p-datatable {
  .p-datatable-table .p-datatable-tbody tr td {
    padding: 10px !important;
  }

  .p-row-editor-save.p-link {
    margin-right: 0;
  }

  .p-editable-column {
    .p-inputnumber-input,
    .p-inputtextarea {
      padding: 5px 10px;
      font-size: 14px;

      &:hover,
      &:focus {
        border-color: variable.$background-color-green;
      }
    }
  }
}
